<template>
  <div class="quota-edit-account-wrap">
    <div class="content-wrap">
      <div class="view-info-wrap">
        <div class="view-account-wrap" :class="{ scrolling }">
          <div class="group-name-wrap">
            <div v-show="parentGroupLabel" class="item-wrap parent">
              <div class="icon">
                <img src="@/assets/icons/group-icon.svg" alt="" />
              </div>
              <span class="label">{{ parentGroupLabel }}</span>
            </div>
            <div class="item-wrap child" :class="{ group: nodeData.uiIsGroup }">
              <div class="icon">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  :disabled="!nodeData.uiImgLabel"
                  :content="nodeData.uiImgLabel"
                >
                  <img :src="nodeData.uiImg" alt="" />
                </el-tooltip>
              </div>
              <span class="label">{{ nodeLabel }}</span>
            </div>
          </div>

          <div v-show="!bEdit" class="account-btn-wrap">
            <SmallTextBtn
              :active="bEdit"
              :disabled="editing || bEditQuota"
              :label="$t('edit') /*編輯*/"
              @click="onEdit"
            />
          </div>
        </div>
        <div class="view-account-used">
          <div class="icon">
            <img :src="enableImg" alt="" />
          </div>
          <span class="label">{{ enableLabel }}</span>
        </div>
        <div class="view-account-locked">
          <span v-if="!bEdit && nodeData.suspendedTime" class="label view">
            {{ $t('account_suspend_view', { time: suspendedTimeStr }) }}
          </span>
          <template v-if="bEdit">
            <template v-if="nodeData.suspendedTime">
              <span class="label edit suspend">
                {{ $t('account_suspend_view', { time: suspendedTimeStr }) }}
              </span>
              <button class="unlock" :disabled="false" @click="onUnlock">
                <img src="@/assets/icons/unlocked.svg" alt="" />
              </button>
            </template>
            <template v-else>
              <span class="label edit">
                {{ $t('account_suspend_edit') }}
              </span>
              <Select
                class="suspend-time"
                v-model="suspend"
                :options="suspendedTimeOptions"
                effect="dark"
                :placeholder="$t('search_device_placehold') /*請選擇*/"
              />
            </template>
          </template>
        </div>
      </div>
      <div class="view-detail-wrap" @scroll="onScroll">
        <PropertyItem
          v-show="!nodeData.uiIsDevice"
          class="item-wrap"
          :labelIcon="require('@/assets/icons/account_detail_name.svg')"
          :label="$t('account_label') /*帳號名稱*/"
          :value="nodeData.info.name"
        />
        <PropertyItem
          v-show="nodeData.uiIsDevice"
          class="item-wrap"
          :labelIcon="require('@/assets/icons/account_detail_video_title.svg')"
          :label="$t('account_video_title') /*影片標題*/"
          :value="nodeData.video.title"
          :helper="
            nodeData.uiOnline
              ? require('@/assets/icons/triangle_left.svg')
              : null
          "
          :helperActive="false"
          :helperTooltip="nodeData.uiOnline && !nodeData.uiLive ? $t('account_not_lived') : null"
          @helper="() => onOpenHelper('acc', nodeData)"
        />
        <PropertyItem
          class="item-wrap"
          :labelIcon="require('@/assets/icons/earth.svg')"
          label="IP"
          :value="accIpLabel"
        />

        <div class="item-wrap viewer">
          <span class="viewer-title">{{ $t('am_group_watching') }}</span>
          <div class="viewer-wrap">
            <img v-if="isOut" src="@/assets/icons/device_model_0.svg" alt="" />
            <img v-else src="@/assets/icons/user.svg" alt="" />
            <span class="viewer-count">
              {{ isOut ? vieweds.length : viewers.length }}
            </span>
          </div>
        </div>

        <template v-if="isOut">
          <!-- 收看 -->
          <template v-for="(dev, idx) of vieweds">
            <PropertyItem
              class="item-wrap"
              :key="`${dev.id}_${idx}_0`"
              :labelIcon="require('@/assets/icons/device_model_0.svg')"
              :label="$t('am_group_account_device')"
              :value="deviceViewedLabel(dev)"
              :helper="
                nodeData.uiOnline
                  ? require('@/assets/icons/triangle_left.svg')
                  : null
              "
              :helperActive="false"
              @helper="() => onOpenHelper('dev', dev)"
            />
            <PropertyItem
              class="item-wrap"
              :key="`${dev.id}_${idx}_1`"
              :labelIcon="require('@/assets/icons/earth.svg')"
              label="IP"
              :value="dev.ip"
            />
            <div
              v-show="idx < vieweds.length - 1"
              class="item-divider"
              :key="`${dev.id}_${idx}_2`"
            >
              <div class="divider"></div>
            </div>
          </template>
        </template>
        <template v-else>
          <!-- 發布 -->
          <template v-for="(viewer, idx) of viewers">
            <!-- {{ JSON.stringify(dev, ' ', 2) }} -->
            <PropertyItem
              class="item-wrap"
              :key="`${viewer.user.id}_${idx}_0`"
              :labelIcon="require('@/assets/icons/user.svg')"
              :label="$t('am_group_account_user')"
              :value="userViewerLabel(viewer)"
            />
            <PropertyItem
              class="item-wrap"
              :key="`${viewer.user.id}_${idx}_1`"
              :labelIcon="require('@/assets/icons/earth.svg')"
              label="IP"
              :value="viewer.ip"
            />
            <div
              v-show="idx < viewers.length - 1"
              class="item-divider"
              :key="`${viewer.id}_${idx}_2`"
            >
              <div class="divider"></div>
            </div>
          </template>
        </template>

        <div class="item-wrap last"></div>
      </div>

      <!-- footer -->
      <div v-show="bEdit" class="footer">
        <button class="btn cancel" :disabled="false" @click="onCalcelEdit">
          {{ $t('cancel') }}
        </button>
        <button
          class="btn confirm"
          :disabled="!hasModified"
          @click="onConfirmEdit"
        >
          {{ $t('confirm') }}
        </button>
      </div>
    </div>

    <GroupDeviceInfo
      v-if="bDevInfo"
      :devInfo="devInfo"
      @close="onCloseHelper"
    />
    <MessageModal
      v-if="bConfirm"
      :header="confirm.header"
      :title="confirm.title"
      :btns="confirm.btns"
      @close="onCalcelEdit"
      @cancel="onCalcelEdit"
      @confirm="confirm.confirmFunc"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import { mergeNameId } from '@/utils/'
import { YYYYMMDDHHmm } from '@/utils/lib.js'
import {
  SuspendTimeList,
  // userItems,
  // deviceItems,
  // getDeviceModelIcon
} from '@/config/account.js'
import {
  apiCheckStatus,
  apiErrorMsg,

  // apiEditUser,
  apiLockUser,
  apiUnlockUser
} from '@/api/index.js'

import SmallTextBtn from '@/components/Base/SmallTextBtn.vue'
import Select from '@/components/AccountManagement/tools/Select.vue'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import GroupDeviceInfo from '@/components/AccountManagement//portal/GroupDeviceInfo.vue'
import MessageModal from '@/components/Base/MessageModal.vue'

const initConfirm = {
  header: null,
  title: null,
  btns: { cancel: true, confirm: true },
  confirmFunc: () => null,
}

export default {
  name: 'GroupQuatoEditAccount',
  components: {
    SmallTextBtn,
    Select,
    PropertyItem,
    GroupDeviceInfo,
    MessageModal
  },
  props: {
    nodeData: {
      type: Object,
      default: null
    },
    rawTreeData: {
      type: Array,
      default() {
        return []
      }
    },
    parentGroupLabel: {
      type: String,
      default: null
    },
    nodeLabel: {
      type: String,
      default: null
    },
    // layerBase: {
    //   type: Number,
    //   // default: 0
    // }
    canEdit: {
      type: Boolean,
      default: true
    },
    bEditQuota: {
      type: Boolean,
      default: false
    },
    isOut: {
      type: Boolean,
      default: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bEdit: false,
      scrolling: false,

      bDevInfo: false,
      devInfo: null,

      suspend: null,

      bConfirm: false,
      confirm: {
        ...initConfirm
      }
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2']),
    ...mapState('userinfo', [
      'deviceModels',
      // 'groupTreeList',
      'groupInfoList',
      'accInfoList',
      'srvLiveList'
    ]),
    suspendedTimeStr() {
      return moment(this.nodeData.suspendedTime).format(YYYYMMDDHHmm)
    },
    suspendedTimeOptions() {
      return SuspendTimeList.map((_t) => {
        return {
          value: _t,
          label: !_t ? this.$t('none') : this.$t('n_min', { min: _t })
        }
      })
    },
    enableImg() {
      return this.nodeData.enabled
        ? require('@/assets/icons/success-check.svg')
        : require('@/assets/icons/failed.svg')
    },
    enableLabel() {
      return this.nodeData.enabled ? this.$t('enabled') : this.$t('disabled')
    },
    liveDevice() {
      const acc = this.srvLiveList.find(
        (_acc) => _acc.user.id === this.nodeData.id
      )

      return acc ? acc : null
    },
    viewers() {
      // 該設備的觀看者 => 人
      return this.liveDevice ? this.liveDevice.viewer : []
    },
    vieweds() {
      // 該使用者觀看的設備 => 設備
      const list = []
      this.srvLiveList.forEach((_live) => {
        _live.viewer.forEach((_v) => {
          if (_v.user.id === this.nodeData.id) {
            list.push(_live)
          }
        })
      })
      return list
    },
    accIpLabel() {
      if (this.isOut) {
        const viewed = this.vieweds.find(
          (_viewed) => _viewed.viewer[0].user.id === this.nodeData.id
        )

        return viewed ? viewed.viewer[0].ip : ''
      } else return this.liveDevice ? this.liveDevice.ip : ''
    },
    hasModified() {
      const { suspendedTime } = this.nodeData

      if (suspendedTime !== this.suspend) return true

      return false
    },
  },
  watch: {
    // nodeData: {
    //   deep: true,
    //   handler() {
    //     console.log(`[GroupQuatoEditAccount.W.nodeData]`)
    //   }
    // }
  },
  methods: {
    ...mapActions('userinfo', [
      'getOnlineList',
      'getSrvLiveList',
      'fetchAccountManageList'
    ]),
    ...mapMutations([]),
    itemValue(item) {
      console.log(`[itemValue] item:`, item)
    },
    itemValueIcon(item) {
      console.log(`[itemValueIcon] item:`, item)
    },
    // itemHelperIcon(item) {
    //   console.log(`[itemHelperIcon] item:`, item)
    // },
    userViewerLabel(dev) {
      const acc = this.accInfoList.find((_acc) => _acc.id === dev.user.id)
      let label = mergeNameId(acc ? acc.info.name : '', dev.user.id) // `${acc ? acc.info.name : ''}(${dev.user.id})`

      return label
    },
    deviceViewedLabel(dev) {
      // console.log(`[deviceViewedLabel] dev:`, dev)
      return mergeNameId(dev.user.video.title, dev.user.id) // `${dev.user.video.title}(${dev.user.id})`
    },
    getNodeData(treeList, id) {
      for (const node of treeList) {
        if (String(node.id) === String(id)) {
          return node
        }

        if (node.children?.length > 0) {
          const newNode = this.getNodeData(node.children, id)
          if (newNode) {
            return newNode
          }
        }
      }
    },

    // ---
    onEdit() {
      this.bEdit = true // !this.bEdit
      this.$emit('set-edit')
    },
    onScroll($event) {
      if (!this.scrolling) {
        if ($event.target.scrollTop > 0) this.scrolling = true
      }

      if (this.scrolling) {
        if ($event.target.scrollTop === 0) this.scrolling = false
      }
    },
    async onOpenHelper(src, data) {
      // console.log(`[onOpenHelper] ${src} data:`, data)
      let tmpData =
        src === 'acc' ? data : this.getNodeData(this.rawTreeData, data.user.id)

      this.devInfo = tmpData

      if (this.devInfo) {
        this.bDevInfo = true
      }
    },
    onCloseHelper() {
      this.bDevInfo = false
      this.devInfo = null
    },
    async onCalcelEdit() {
      this.suspend = null
      this.bConfirm = false
      this.confirm = { ...initConfirm }

      this.bEdit = false
      this.$emit('set-edit')

      await this.fetchAccountManageList()
      this.$emit('get-current')
    },
    async onApiLock() {
      const title = '鎖定帳號'
      try {
        const param = {
          id: this.nodeData.id,
          suspend: this.suspend
        }

        console.log(`[onApiLock] param:`, param)
        const res = await apiLockUser(param)
        if (!apiCheckStatus(res)) throw res
        this.$notify({
          type: 'success',
          title,
          message: this.$t('success')
        })
      } catch (err) {
        console.error(`[onApiEdit] fail.`, err)
        this.$notify({
          type: 'error',
          title,
          message:
            this.$t('fail') + err
              ? this.$t(apiErrorMsg(err))
              : this.$t('api_error')
        })
      } finally {
        await this.onCalcelEdit()
      }
    },
    async onConfirmEdit() {
      const name = this.nodeData.info.name || this.nodeData.id

      this.confirm = {
        ...this.confirm,
        ...{
          header: '鎖定帳號',
          title: `您確定要鎖定 ${name} 嗎？`,
          confirmFunc: this.onApiLock
        }
      }

      this.bConfirm = true
    },
    async onApiUnlock() {
      const title = '解鎖帳號'
      try {
        const res = await apiUnlockUser(this.nodeData.id)
        if (!apiCheckStatus(res)) throw res
        this.$notify({
          type: 'success',
          title,
          message: this.$t('success')
        })
        console.log(`[onUnlock]`)
        // notify pass
      } catch (err) {
        this.$notify({
          type: 'error',
          title,
          message:
            this.$t('fail') + err
              ? this.$t(apiErrorMsg(err))
              : this.$t('api_error')
        })
      } finally {
        await this.onCalcelEdit()
      }
    },
    async onUnlock() {
      const name = this.nodeData.info.name || this.nodeData.id

      this.confirm = {
        ...this.confirm,
        ...{
          header: '解鎖帳號',
          title: `您確定要解鎖 ${name} 嗎？`,
          confirmFunc: this.onApiUnlock
        }
      }

      this.bConfirm = true
    }
  },
  created() {},
  mounted() {
    this.getSrvLiveList()
    console.log(`[GroupMainEditAccount.mounted] srvLiveList:`, this.srvLiveList)
    // console.log(`[GroupMainEditAccount.mounted] rawTreeData:`, this.rawTreeData)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.quota-edit-account-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // background-color: #f00;

  .content-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    // .view-info-wrap {}
    // .view-detail-wrap {}
    .footer {
      display: flex;
      justify-content: center;
      // margin-top: px2rem(32);
      border-top: 1px solid $color_4A5C78;
      padding: px2rem(12) 0;
      width: 100%;
      .btn {
        margin-right: px2rem(32);
        border: 1px solid transparent;
        border-radius: px2rem(8);
        width: px2rem(114);
        height: px2rem(44);
        color: $color_FFF;
        &.cancel {
          border-color: $color_E6E6E6;
          &:hover {
            background-color: $color_E6E6E6_10;
          }
        }
        &.confirm {
          color: $color_282942;
          background-color: $color_FFC600;
          &:not(:disabled):hover {
            background-color: $color_FFD133;
          }
          &:disabled {
            @include disabled;
          }
        }
      }
    }
  }

  .view-info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // margin-bottom: 0.5rem;
    // height: 100%;
    .view-account-wrap {
      // position: fixed;
      display: flex;
      padding-top: px2rem(12);
      width: 100%;
      // background-color: #ff0;

      &.scrolling {
        // background-color: $color_151B35_20;
      }
      .group-name-wrap {
        display: flex;
        flex-direction: column;
        // width: 100%;
        flex-grow: 1;
        // background-color: #f00;

        .item-wrap {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;
          padding-left: px2rem(12);

          &:first-child {
            margin-top: unset;
          }

          .icon {
            display: flex;
            align-items: center;
            img {
              width: 1rem;
              height: 1rem;
            }
          }
          &.parent {
            color: $color_FFC600;
            .label {
              @include font_bold;
            }
          }
          &.child.group {
            .icon {
              @include filter_FFF;
            }
          }
          .label {
            margin-left: 0.5rem;
            word-break: break-all;
          }
        }
      }

      .account-btn-wrap {
        display: flex;
        padding-right: px2rem(12);
        // background-color: #00f;
        button {
          margin-left: px2rem(12);
        }
      }
    }

    // 啟用/停用
    .view-account-used {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      padding-left: px2rem(12);
      // padding-right: px2rem(48); // 12 + 36
      line-height: 1.5;

      img {
        width: 1rem;
        height: 1rem;
      }

      .label {
        flex-grow: 1;
        margin-left: 0.5rem;
        // background-color: #f00;
      }
    }

    // 鎖定/解鎖
    .view-account-locked {
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin-top: 0.5rem;
      padding-left: px2rem(12);
      padding-right: px2rem(12); // 12 + 36
      line-height: 1.5;
      // background-color: #0f0;

      .label {
        color: $color_locked;
        &.edit {
          color: $color_FFF;
        }
      }
      .suspend-time:deep {
        margin-left: 0.5rem;
        width: px2rem(140);
        height: px2rem(36);

        .select {
          height: 100%;
        }
      }

      .unlock {
        margin-left: 0.5rem;
        &:hover {
          cursor: pointer;
        }
        img {
          width: 1rem;
          height: 1rem;
          @include filter_locked;
        }
      }
    }
  }

  .view-detail-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    width: 100%;
    overflow-y: scroll;
    // background-color: #00f;
    .item-wrap {
      margin-top: 0.5rem;
      padding-left: px2rem(12);
      &:first-child {
        margin-top: unset;
      }
      &:last-child {
        margin-bottom: 0.5rem;
        height: px2rem(12);
      }

      &.viewer {
        display: flex;
        margin-top: px2rem(24);
        margin-bottom: px2rem(8);
        .viewer-title {
          color: $color_FFE99F;
          @include font_bold;
        }
        .viewer-wrap {
          margin-left: 0.5rem;
          align-items: flex-end;
          color: $color_FFF_50;
          img {
            width: 0.75rem; // 12px
            height: 0.75rem;
            @include filter_FFF_50;
          }
          .viewer-count {
            margin-left: 0.125rem;
            font-size: 0.875rem; // 14px
          }
        }
      }
    }

    .item-divider {
      padding-top: px2rem(12);
      padding-bottom: px2rem(4); // 12 - 8
      padding-left: px2rem(12);
      .divider {
        @include horzontal_divider;
      }

      // background-color: #00f;
    }
  }
}
</style>
