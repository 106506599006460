<template>
  <SmallWin class="win-wrap" :winWidth="660" @close="$emit('close')">
    <template #small-win-title>
      <div class="top-wrap">
        <div class="group-name">
          <img src="@/assets/icons/group-icon.svg" alt="" />
          <span>{{ groupLabel }}</span>
        </div>
        <div class="device-title">
          <el-tooltip
            popper-class="el-tooltip"
            effect="dark"
            v-delTabIndex
            :disabled="devInfo?.uiImgLabel ? false : true"
            :content="devInfo?.uiImgLabel"
          >
            <img :src="devInfo?.uiImg" alt="" />
          </el-tooltip>
          <span>{{
            mergeNameId(
              devInfo.video.title,
              devInfo.id
            ) /*`${devInfo.video.title}(${devInfo.id})`*/
          }}</span>
        </div>
      </div>
    </template>
    <template #small-win-detail>
      <div class="device-info-wrap">
        <div class="body-wrap">
          <div class="img-control-wrap">
            <SmallBtn
              v-if="false"
              class="link-btn"
              :disabled="false"
              :img="require('@/assets/icons/link.svg')"
            />
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :disabled="false"
              :content="linkTooltip"
            >
              <a class="link-btn" :href="linkHref" target="_blank">
                <img src="@/assets/icons/link.svg" alt="" />
              </a>
            </el-tooltip>
            <ImgSwitch
              class="img-switch"
              v-model="imgMode"
              :options="imgModes"
              @switch="onSwitchImgMode"
            />
          </div>
          <div class="img-display">
            <div v-show="isPhoto" class="photo">
              <img v-if="photoSrc" :src="photoSrc" alt="" />
              <img v-else src="@/assets/icons/no-image.svg" alt="" />
            </div>
            <div v-show="isVideo" class="video">
              <template v-if="!mseErrMsg">
                <video
                  v-if="videoSrc"
                  class="video"
                  ref="videoPlayer"
                  controls
                  @click.prevent
                >
                  <source :src="videoSrc" />
                </video>
                <img v-else src="@/assets/icons/no-video.svg" alt="" />
              </template>
              <template v-else>
                <div class="video-error">
                  <span>{{ mseErrMsg }}</span>
                </div>
              </template>
            </div>
          </div>
          <!-- 名稱 -->
          <PropertyItem class="item-wrap" :title="$t('am_group_dev_info_name')" />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/account_detail_name.svg')"
            :label="$t('am_group_dev_info_info_name' /*設備名稱*/)"
            :value="devInfo.info.name"
          />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/account_detail_video_title.svg')"
            :label="$t('am_group_dev_info_video_title' /*影片標題*/)"
            :value="devInfo.video.title"
          />
          <!-- 屬性 -->
          <PropertyItem class="item-wrap" :title="$t('am_group_dev_info_property')" />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/udid.svg')"
            :label="$t('am_group_dev_info_device_id' /*設備序號*/)"
            :value="deviceSn"
          />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/account_detail_category.svg')"
            :label="$t('am_group_dev_info_device_model_id' /*設備類型*/)"
            :value="deviceModelName"
            :valueIcon="devInfo.uiImg"
          />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/account_detail_category.svg')"
            :label="$t('am_group_dev_info_device_info_type' /*裝置類型*/)"
            :value="deviceType"
          />
          <!-- 最新活動紀錄 -->
          <PropertyItem
            class="item-wrap"
            :title="$t('am_group_dev_info_latest_action')"
          />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/gps.svg')"
            :label="$t('am_group_dev_info_gps' /*GPS*/)"
            :value="gpsValue"
          >
            <template #value>
              <span>{{ gpsValue }}</span>
              <img
                class="gps-icon"
                src="@/assets/icons/google-map-icon.png"
                @click="onGoGoogleMap"
                alt=""
              />
            </template>
          </PropertyItem>
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/time-solid.svg')"
            :label="$t('am_group_dev_info_gps_timestamp' /*時間*/)"
            :value="gpsTimestamp"
          />
          <div class="empty"></div>
        </div>
      </div>
    </template>
  </SmallWin>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  // mapGetters,
  // mapMutations,
  mapActions
} from 'vuex'
import mse from '@/api/mse.js'
import { goGoogleMap, mergeNameId } from '@/utils/index.js'
import { YYYYMMDDHHmm } from '@/utils/lib.js'
import SmallWin from '@/components/Base/SmallWin.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import ImgSwitch from '@/components/Base/ImgSwitch.vue'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'

export default {
  name: 'GroupDeviceInfo',
  components: { SmallWin, SmallBtn, ImgSwitch, PropertyItem },
  props: {
    devInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      imgMode: 'photo',
      imgModes: [
        {
          id: 'photo',
          disabled: false,
          img: require('@/assets/icons/def-image.svg'),
          // label: 'Photo',
          // tooltip: 'Photo'
        },
        {
          id: 'video',
          disabled: false,
          img: require('@/assets/icons/play-video.svg'),
          // label: 'Video',
          // tooltip: 'Video'
        }
      ],

      mseObj: null,
      mseErrMsg: null
    }
  },
  computed: {
    ...mapState('userinfo', ['groupInfoList', 'deviceModels', 'srvLiveList']),
    liveInfo() {
      const liveDev = this.srvLiveList.find(
        (srv) => srv.user.id === this.devInfo.id
      )
      return liveDev
    },
    groupLabel() {
      const parent = this.groupInfoList.find(
        (_g) => _g.id === this.devInfo?.groupId
      )
      return parent ? parent.name : ''
    },
    isPhoto() {
      return this.imgMode === 'photo'
    },
    photoSrc() {
      if (this.liveInfo) {
        if (this.isPhoto) {
          return this.liveInfo.snapshotUrl
        }
      }
      return null
    },
    isVideo() {
      return this.imgMode === 'video'
    },
    videoSrc() {
      if (this.isVideo) {
        if (this.liveInfo) {
          return this.liveInfo.mseUrl
          // return this.liveInfo.hlsUrl
          // return this.liveInfo.videoUrl // rtsp
        }
      }
      return null
    },
    linkTooltip() {
      return this.imgMode === 'photo'
        ? this.$t('am_group_dev_info_snapshot_link_tooltop')
        : this.$t('am_group_dev_info_streaming_link_tooltop')
    },
    linkHref() {
      if (this.liveInfo) {
        if (this.isPhoto) {
          return this.liveInfo.snapshotUrl
        } else if (this.isVideo) {
          // console.log(`[linkHref] this.liveInfo.videoUrl:`, this.liveInfo.videoUrl)
          return this.liveInfo.videoUrl // rtsp
        }
      }
      return null
    },
    deviceSn() {
      if (this.liveInfo) {
        return this.liveInfo.device.id
      }
      return ''
    },
    deviceModelName() {
      return this.deviceModels.find(
        (model) => model.id === this.devInfo.deviceModelId
      )?.name
    },
    deviceType() {
      if (this.liveInfo) {
        return this.liveInfo.device.info.type
      }
      return ''
    },
    gpsCoordinate() {
      let { latitude, longitude } = this.devInfo.gps

      if (this.liveInfo && this.liveInfo.gps) {
        latitude = this.liveInfo.gps.latitude
        longitude = this.liveInfo.gps.longitude
      }

      return { latitude, longitude }
    },
    gpsValue() {
      let { latitude, longitude } = this.gpsCoordinate

      return `${latitude}, ${longitude}`
    },
    // gpsHref() {
    //   let { latitude, longitude } = this.gpsCoordinate

    //   return `https://maps.google.com/maps?q=${latitude},${longitude}`
    // },
    gpsTimestamp() {
      let timestamp = null
      if (this.liveInfo && this.liveInfo.gps) {
        timestamp = this.liveInfo.gps.timestamp
      }

      return timestamp ? `${moment(timestamp).format(YYYYMMDDHHmm)}` : ''
    },
    // timestamp() {
    //   return ''
    // }
    hasVideoPlayer() {
      const refs = Object.keys(this.$refs)
      return refs.includes('videoPlayer')
    }
  },
  watch: {},
  methods: {
    ...mapActions('userinfo', ['getSrvLiveList']),
    mergeNameId,
    initMse() {
      if (this.hasVideoPlayer) {
        this.mseObj = new mse(this.$refs.videoPlayer, this.videoSrc, this.unSupportedVideoHandler)
        this.mseObj.startup()
      }
    },
    closeMse() {
      if (this.mseObj) {
        this.mseObj.close()
        this.mseObj = null
      }
    },
    playVideo() {
      if (!this.videoSrc) return

      this.closeMse()
      this.initMse()

      this.$refs.videoPlayer.play()
    },
    pauseVideo() {
      if (this.hasVideoPlayer) {
        this.$refs.videoPlayer.pause()
      }
    },
    stopVideo() {
      // this.pauseVideo()

      this.closeMse()
      this.mseErrMsg = null
    },
    // ---
    onSwitchImgMode(mode) {
      this.imgMode = mode

      this.$nextTick(() => {
        if (this.imgMode === 'video') {
          this.playVideo()
        }
      })
    },
    onGoGoogleMap() {
      let { latitude, longitude } = this.gpsCoordinate

      goGoogleMap(latitude, longitude)
    },
    unSupportedVideoHandler(/*codec*/) {
      // console.log(`[unSupportedVideoHandler] codec:`, codec)
      this.mseErrMsg = this.$t('video_unsupported_mime_codec'/*不支援的影片格式*/)
      this.closeMse()
    },
  },
  created() {},
  mounted() {
    this.getSrvLiveList()
    // console.log(`[GroupDeviceInfo.mounted]`)
  },
  beforeDestroy() {
    this.stopVideo()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}

.win-wrap {
  // width: 1000px;
}

.top-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 100%;
  // background-color: #00f;
  .group-name {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    width: 100%;
    @include font_bold;
    color: $color_FFC600;
    img {
      width: 1rem;
      height: 1rem;
    }
    span {
      margin-left: 0.5rem;
    }
  }
  .device-title {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding-left: 2rem;
    img {
      width: 1rem;
      height: 1rem;
    }
    span {
      margin-left: 0.5rem;
    }
  }
}

.device-info-wrap {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  width: 100%;
  height: 100%;

  .body-wrap {
    flex-grow: 1;
    width: 100%;
    height: calc(100% - px2rem(56));
    padding-right: 1rem;

    .img-control-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // margin-top: 0.5rem;

      .link-btn {
        // background-color: #f00;
      }
      .img-switch {
        margin-left: px2rem(12);
      }
    }
    .img-display {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      width: 100%;
      height: px2rem(246); // calc(246px / 720px * 100vh);
      background-color: $color_black;

      img,
      video {
        height: 100%;
        width: 100%;
        object-fit: contain;
        background-color: $color_black;
      }

      .video-error {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: $color_FFF_50;
      }
    }

    .item-wrap {
      .gps {
        color: $color_FFF; // $color_4C68EB;
      }
      .gps-icon {
        margin-left: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .empty {
      height: 0.5rem;
    }
  }
}
</style>
