<template>
  <div class="main-edit-wrap">
    <GroupMainEditGroup
      v-if="nodeData.uiIsGroup"
      :staff="staff"
      :nodeData="nodeData"
      :parentGroupLabel="parentGroupLabel"
      :nodeLabel="nodeLabel"
      :allowEdit="false"
      @update_edit="onUpdateEditSt"
    />
    <GroupQuotaEditAccount
      v-else
      :nodeData="nodeData"
      :rawTreeData="rawTreeData"
      :parentGroupLabel="parentGroupLabel"
      :nodeLabel="nodeLabel"
      :bEditQuota="nodeData.uiIsLoginUser"
      :isOut="isOut"
      :editing="editing"
      @set-edit="$emit('set-edit')"
      @get-current="$emit('get-current')"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import { mergeNameId } from '@/utils/'
import GroupMainEditGroup from '@/components/AccountManagement/group/Edit/GroupMainEditGroup.vue'
import GroupQuotaEditAccount from '@/components/AccountManagement/group/Edit/GroupQuotaEditAccount.vue'

export default {
  name: 'GroupQuotaEdit',
  components: {
    GroupMainEditGroup,
    GroupQuotaEditAccount
  },
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    nodeData: {
      type: Object,
      default: null
    },
    rawTreeData: {
      type: Array,
      default() {
        return []
      }
    },
    layerBase: {
      type: Number,
      // default: 0
    },
    isOut: {
      type: Boolean,
      default: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // bSave: false
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2']),
    ...mapState('userinfo', ['groupTreeList', 'groupInfoList', 'roleList']),
    userInfo() {
      return this.getUserInfo
    },
    parentGroupLabel() {
      if (!this.nodeData) return null

      const { uiIsGroup, parent, groupId } = this.nodeData
      let parentGroupId = null

      if (uiIsGroup) {
        if (this.nodeData.uiLayer === this.layerBase) {
          parentGroupId = null // 最高階不顯示 父群
        } else {
          parentGroupId = Number(parent) ? parent : null
        }
      } else {
        parentGroupId = groupId
      }

      if (!parentGroupId) return null

      return this.groupInfoList.find((gInfo) => gInfo.id === parentGroupId).name
    },
    nodeLabel() {
      if (!this.nodeData) return null

      const { uiIsGroup, name, uiIsDevice, info, video, id } = this.nodeData
      let label = uiIsGroup
        ? name
        : mergeNameId(uiIsDevice ? video.title : info.name, id) //`${uiIsDevice ? video.title : info.name}(${id})`

      return label
    },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    onUpdateEditSt(status) {
      this.$emit('update_edit', status)
    }
  },
  created() {},
  mounted() {
    console.log(`[GroupQuotaEdit.mounted] nodeData:`, this.nodeData)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.main-edit-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  margin-bottom: 0.125rem;
  // padding-top: px2rem(12);
}
</style>
