<template>
  <div class="main-edit-group-wrap">
    <div class="view-info-wrap">
      <div class="view-group-name">
        <div class="group-name-wrap">
          <!-- 所屬群組 -->
          <div v-if="parentGroupLabel" class="item-wrap parent">
            <template v-if="!bSave">
              <div class="icon">
                <img src="@/assets/icons/group-icon.svg" alt="" />
              </div>
              <span class="label">
                <!-- {{ bAdd ? nodeLabel : parentGroupLabel }} -->
                {{ parentGroupLabel }}
              </span>
            </template>
          </div>
          <template v-if="bSave && bAdd">
            <PropertyItemEdit
              class="edit-item-wrap"
              :style="{ color: '#fff' }"
              :labelIcon="require('@/assets/icons/group-icon.svg')"
              :label="$t('am_parent_group') /*所屬群組-new*/"
              :helper="null"
              :error="null"
              @input="onGetParentGroupId"
            >
              <template #property-value>
                <SelectTree
                  class="tree-value"
                  v-model="newGroup.parent"
                  :disabled="saving"
                  :options="treeOptions"
                  :placeholder="$t('am_parent_group_hint')"
                />
              </template>
            </PropertyItemEdit>
          </template>
          <template v-else-if="parentGroupLabel && bSave && bEdit">
            <PropertyItemEdit
              class="edit-item-wrap"
              :style="{ color: '#fff' }"
              :labelIcon="require('@/assets/icons/group-icon.svg')"
              :label="$t('am_parent_group') /*所屬群組-edit*/"
              :helper="null"
              :error="groupError"
              @input="onGetParentGroupId"
            >
              <template #property-value>
                <SelectTree
                  class="tree-value"
                  v-model="currGroup.parent"
                  :options="treeOptions"
                  :disabled="saving || groupError ? true : false"
                  :disabledGroupId="nodeData.id"
                  :placeholder="$t('am_parent_group_hint')"
                />
              </template>
            </PropertyItemEdit>
          </template>
          <!-- 群組名稱 -->
          <div v-if="!bSave" class="item-wrap child">
            <div class="icon">
              <img src="@/assets/icons/group-icon.svg" alt="" />
            </div>
            <span class="label">{{ groupNameLabel /*nodeLabel*/ }}</span>
          </div>
          <PropertyItemEdit
            v-else-if="bEdit"
            class="edit-item-wrap"
            :hint="
              $t('am_group_name_top', { min: groupLen.min, max: groupLen.max })
            "
            :hintTooltiop="null"
            :labelIcon="require('@/assets/icons/group-icon.svg')"
            :label="$t('am_group_name') /*群組名稱*/"
            :helper="null"
            :error="errMsg.groupName ? $t(errMsg.groupName) : null"
            :required="true"
          >
            <template #property-value>
              <InputText
                class="text-value"
                v-model="currGroup.name"
                :disabled="saving"
                :maxlength="groupLen.max"
                @input="onGetGroupName"
              />
            </template>
          </PropertyItemEdit>
          <PropertyItemEdit
            v-else-if="bAdd"
            class="edit-item-wrap"
            :hint="
              $t('am_group_name_top', { min: groupLen.min, max: groupLen.max })
            "
            :hintTooltiop="null"
            :labelIcon="require('@/assets/icons/group-icon.svg')"
            :label="$t('am_group_name') /*群組名稱*/"
            :helper="null"
            :error="errMsg.groupName ? $t(errMsg.groupName) : null"
            :required="true"
          >
            <template #property-value>
              <InputText
                class="text-value"
                v-model="newGroup.name"
                :disabled="saving"
                :maxlength="groupLen.max"
                :hieght="'100%'"
                @input="onGetGroupName"
              />
            </template>
          </PropertyItemEdit>
        </div>
        <div v-show="allowEdit && !bSave" class="group-btn-wrap">
          <SmallTextBtn
            :active="bAdd"
            :disabled="false"
            :label="$t('am_add_subgroup' /*新增子群組*/)"
            @click="onAdd"
          />
          <SmallTextBtn
            :active="bEdit"
            :disabled="nodeData.uiIsLoginUser"
            :label="$t('edit') /*編輯*/"
            @click="onEdit"
          />
        </div>
      </div>
      <div v-if="!bSave" class="view-group-count">
        <template>
          <img src="@/assets/icons/group-icon.svg" alt="" />
          <span>{{ nodeData.uiKid.group }}</span>
        </template>
        <template>
          <span class="slash">/</span>
          <img src="@/assets/icons/user.svg" alt="" />
          <span>{{ nodeData.uiKid.user }}</span>
        </template>
        <template>
          <span class="slash">/</span>
          <img src="@/assets/icons/device_model_0.svg" alt="" />
          <span>{{ nodeData.uiKid.device }}</span>
        </template>
      </div>
      <div class="view-group-detail">
        <!-- {{ JSON.stringify(nodeData) }} -->
        <template v-if="staff">
          <PropertyItem class="item-wrap" :title="$t('am_group_staff_info')" />
          <PropertyItem
            v-if="!bSave"
            class="item-wrap"
            :labelIcon="require('@/assets/icons/id.svg')"
            :label="$t('am_group_department_code') /*單位代碼*/"
            :value="nodeData.code || 'TODO'"
            :helper="null"
            :error="null"
          />
          <PropertyItemEdit
            v-else-if="bEdit"
            class="edit-item-wrap"
            :labelIcon="require('@/assets/icons/id.svg')"
            :label="$t('am_group_department_code')"
            :value="nodeData.code"
            :valueType="'text'"
            :valueLimit="{ min: 3, max: 32 }"
            :helper="null"
            :error="null"
            @input="onGetDepartmentCode"
          />
          <PropertyItemEdit
            v-else-if="bAdd"
            class="edit-item-wrap"
            :labelIcon="require('@/assets/icons/id.svg')"
            :label="$t('am_group_department_code')"
            :value="newGroup.code"
            :valueType="'text'"
            :valueLimit="{ min: 3, max: 32 }"
            :helper="null"
            :error="null"
            @input="onGetDepartmentCode"
          />
          <PropertyItem
            v-if="!bAdd"
            class="item-wrap"
            :labelIcon="require('@/assets/icons/id.svg')"
            :label="$t('am_group_id') /*群組ID*/"
            :value="nodeData.id"
            :helper="null"
            :error="null"
          />
        </template>
        <template v-if="!bAdd">
          <PropertyItem class="item-wrap" :title="$t('account_time')" />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/time-solid.svg')"
            :label="$t('am_group_updated_time')"
            :value="updatedTime"
            :helper="null"
            :error="null"
          />
          <PropertyItem
            class="item-wrap"
            :labelIcon="require('@/assets/icons/time-solid.svg')"
            :label="$t('am_group_create_time')"
            :value="createdTime"
            :helper="null"
            :error="null"
          />
        </template>
      </div>
    </div>
    <div v-show="bSave" class="footer">
      <button class="btn cancel" :disabled="saving" @click="onCalcel">
        {{ $t('cancel') }}
      </button>
      <button
        v-show="bAdd"
        class="btn confirm"
        :disabled="bAdd && !verifyAdd"
        @click="onConfirmAdd"
      >
        {{ $t('confirm') }}
      </button>
      <button
        v-show="bEdit"
        class="btn confirm"
        :disabled="bEdit && !modifySomething"
        @click="onConfirmEdit"
      >
        {{ $t('confirm') }}
      </button>
    </div>
    <MessageModal
      v-if="bConfirm"
      :header="confirm.header"
      :title="confirm.title"
      :btns="confirm.btns"
      @close="bConfirm = false"
      @cancel="bConfirm = false"
      @confirm="confirm.confirmFunc"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import {
  getNode,
  // FuzzyStrComp,
  // getAncestorList,
  A2ZSort,
  YYYYMMDDHHmm,
  // 帳號相關 ---
  groupLen,
  validateGroupNameLen,
  vertifyStrLen
} from '@/utils/lib.js'
import {
  apiEditGroup,
  apiCreateGroup,
  apiCheckStatus,
  apiErrorMsg
} from '@/api/index.js'
import SelectTree from '@/components/AccountManagement/tools/SelectTree2.vue'
import InputText from '@/components/AccountManagement/tools/InputText.vue'
import SmallTextBtn from '@/components/Base/SmallTextBtn.vue'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PropertyItemEdit from '@/components/AccountManagement/tools/PropertyItemEdit.vue'
import MessageModal from '@/components/Base/MessageModal.vue'

const initConfirm = {
  header: null,
  title: null,
  btns: { cancel: true, confirm: true },
  confirmFunc: () => null,
}

export default {
  name: 'GroupMainEditGroup',
  components: {
    SelectTree,
    InputText,
    SmallTextBtn,
    PropertyItem,
    PropertyItemEdit,
    MessageModal
  },
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    nodeData: {
      type: Object,
      default: null
    },
    parentGroupLabel: {
      type: String,
      default: null
    },
    nodeLabel: {
      type: String,
      default: null
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      groupLen,

      bAdd: false,
      bEdit: false,
      saving: false,

      orgGroup: {
        id: null,
        parent: null,
        name: null,
        code: null
      },
      currGroup: {
        id: null,
        parent: null,
        name: null,
        code: null
      },
      newGroup: {
        parent: null,
        name: null,
        code: null
      },
      errMsg: {
        groupName: null,
        departmentCode: null, // TODO
      },

      bConfirm: false,
      confirm: {
        ...initConfirm
      }
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2']),
    ...mapState('userinfo', ['groupTreeList', 'groupInfoList']),
    groupNameLabel() {
      if (this.currGroup) {
        return this.currGroup.name
      }
      return this.nodeLabel

      // return ''
    },
    groupError() {
      if (this.groupHasAccount) {
        return this.$t(
          `am_quota_error_group_has_account`,
          { name: this.nodeData.name }
          /*`群組 ${this.nodeData.name} 尚有帳號, 故不能調整所屬群組`*/
        )
      } else if (this.groupHasQuota.both || this.groupHasQuota.in || this.groupHasQuota.out) {
        if (this.groupHasQuota.both) {
          return this.$t(
            `am_quota_error_group_has_both_quota`,
            { name: this.nodeData.name }
            /*`群組 ${this.nodeData.name} 尚有發布及收看授權, 故不能調整所屬群組`*/
          )
        } else if (this.groupHasQuota.in) {
          return this.$t(
            `am_quota_error_group_has_in_quota`,
            { name: this.nodeData.name }
            /*`群組 ${this.nodeData.name} 尚有發布授權, 故不能調整所屬群組`*/
          )
        } else if (this.groupHasQuota.out) {
          return this.$t(
            `am_quota_error_group_has_out_quota`,
            { name: this.nodeData.name }
            /*`群組 ${this.nodeData.name} 尚有收看授權, 故不能調整所屬群組`*/
          )
        }
      }

      return null
    },
    groupHasAccount() {
      return this.nodeData.uiKid.user > 0 || this.nodeData.uiKid.device > 0
    },
    groupHasQuota() {
      return {
        both: this.nodeData.maxIn > 0 && this.nodeData.maxOut > 0,
        in: this.nodeData.maxIn > 0,
        out: this.nodeData.maxOut > 0
      }
    },
    bSave() {
      return this.bAdd || this.bEdit
    },
    updatedTime() {
      return this.nodeData
        ? moment(this.nodeData.updateTime).format(YYYYMMDDHHmm)
        : null
    },
    createdTime() {
      return this.nodeData
        ? moment(this.nodeData.createdTime).format(YYYYMMDDHHmm)
        : null
    },
    treeOptions() {
      if (!this.groupTreeList || !this.groupInfoList) return []

      const data = JSON.parse(JSON.stringify(this.groupTreeList))
      data.forEach((node) => this.mergeGroupTree(node))
      return data
    },
    modifySomething() {
      const keys = Object.keys(this.orgGroup)

      for (const key of keys) {
        if (this.orgGroup[key] !== this.currGroup[key]) {
          return true
        }
      }
      return false
    },
    verifyAdd() {
      const errKeys = Object.keys(this.errMsg)

      for (let key of errKeys) {
        if (key === 'groupName') {
          if (this.errMsg[key] === 'account_required_info') return false
        } else {
          if (this.errMsg[key]) return false
        }
      }

      return true
    },
  },
  watch: {
    bSave() {
      this.$emit('update_edit', this.bSave)
    },
    nodeData: {
      deep: true,
      handler() {
        this.init(this.nodeData)
      }
    }
  },
  methods: {
    ...mapActions('userinfo', [
      'getGroupList',
      'getGroupInfoList',
      'getUserTree',
      // 'getAccInfoList',
      // 'getRoleList',
      // 'getUserInfoList',
      // 'getDeviceInfoList',
      // 'getDeviceModels',
      // 'getOnlineList'
    ]),
    ...mapMutations([]),
    validateGroupNameLen,
    vertifyStrLen,
    mergeGroupTree(node) {
      // 在这里扩展节点信息
      const grpInfo = this.groupInfoList.find((gInfo) => gInfo.id === node.id)

      // Copy data
      if (grpInfo) {
        const {
          auth,
          createdTime,
          id,
          layer,
          maxIn,
          maxOut,
          name,
          parent,
          updatedTime
        } = grpInfo

        node.auth = auth
        node.createdTime = createdTime
        node.id = id
        node.layer = layer
        node.maxIn = maxIn
        node.maxOut = maxOut
        node.name = name
        node.parent = parent
        node.updatedTime = updatedTime

        // UI used
        node.uiIsGroup = true
        node.uiImg = require('@/assets/icons/group-icon.svg')
        node.uiIsStaff = grpInfo.parent === '0' ? true : false
        node.uiKey = `g#${grpInfo.id}`
        node.uiLabel = grpInfo.name
        node.uiLayer = grpInfo.layer
        node.uiOpened = false
      }

      // 递归处理子节点
      if (node.children) {
        node.children
          .sort((a, b) => A2ZSort(a.name, b.name)) // sort by group name
          .forEach((childNode) => this.mergeGroupTree(childNode))
      }
    },
    verifyGroupName(group) {
      this.errMsg.groupName = null

      // 檢查空群組名稱
      if (!group.name) {
        this.errMsg.groupName = 'account_required_info'
        return
      }

      // 檢查重複群組名稱
      const exist = this.groupInfoList.find((gInfo) => {
        if (this.bAdd) {
          return gInfo.name === group.name
        } else if (this.bEdit) {
          return gInfo.id !== group.id && gInfo.name === group.name // 跳過自己
        }
      })

      if (exist) {
        this.errMsg.groupName = 'am_group_duplicate_name'
      }
    },
    // ---
    init(src) {
      const { parent, id, name } = src
      this.currGroup = {
        id,
        parent,
        name,
        code: null // TODO
      }

      this.orgGroup = { ...this.currGroup }
    },
    onAdd() {
      // TODO:權限
      this.bAdd = true
      this.$emit('set-edit')

      const { id } = this.nodeData
      this.newGroup = {
        parent: id,
        name: this.$t('am_group_name_hint'),
        code: null // TODO
      }
    },
    onEdit() {
      // TODO:權限
      this.bEdit = true
      this.$emit('set-edit')
      // this.init(this.nodeData)
    },
    onGetParentGroupId(id) {
      if (this.bAdd) {
        this.newGroup = { ...this.newGroup, ...{ parent: id } }
      } else if (this.bEdit) {
        const node = getNode(this.treeOptions, id)
        this.currGroup = { ...this.currGroup, ...{ parent: node.parent } }
      }
    },
    onGetGroupName(name) {
      if (this.bAdd) {
        this.newGroup = { ...this.newGroup, ...{ name } }
        this.verifyGroupName(this.newGroup)
      } else if (this.bEdit) {
        this.currGroup = { ...this.currGroup, ...{ name } }
        this.verifyGroupName(this.currGroup)
      }
    },
    onGetDepartmentCode(code) {
      this.nodeData.code = code
    },
    onCalcel() {
      // rollback original data
      this.currGroup = { ...this.orgGroup }

      this.bAdd = false
      this.bEdit = false
      this.$emit('set-edit')

      this.errMsg.groupName = null
    },
    onSetSaving(saving) {
      this.saving = saving
    },
    errorMsg(err) {
      return this.$t('fail') + err
        ? this.$t(apiErrorMsg(err))
        : this.$t('api_error')
    },
    async onApiAdd() {
      const title = this.$t('am_group_add_notify')
      try {
        this.onSetSaving(true)

        const { parent, name } = this.newGroup
        // console.log(`[onApiAdd] id, parent, name:`, id, parent, name)
        let param = {
          auth: 0,
          parent, // 所屬群組
          name // 群組名稱
        }

        const res = await apiCreateGroup(param)
        if (!apiCheckStatus(res)) throw res
        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
          // offset: this.notifyOffset
        })
      } catch (err) {
        console.error(`[onApiAdd] fail`, err)
        this.$notify({
          type: 'error',
          title,
          message: this.errorMsg(err),
          // offset: this.notifyOffset
        })
      } finally {
        // 更新群組
        await this.getGroupInfoList()
        await this.getUserTree()
        await this.getGroupList()

        this.$nextTick(async () => {
          this.bAdd = false
          this.$emit('set-edit')
          this.onSetSaving(false)
          // this.$emit('select', this.currGroup)
          this.init(this.currGroup) // fake

          if (this.bConfirm) {
            this.confirm = { ...initConfirm }
            this.bConfirm = false
          }
        })
      }
    },
    async onConfirmAdd() {
      // 檢查群組名
      if (!this.verifyAdd) {
        console.log(`[onConfirmAdd] newGroup:`, this.newGroup)
        return
      }

      this.confirm = {
        ...this.confirm,
        ...{
          header: this.$t('am_group_add_notify'),
          title: this.$t('am_group_create_confirm'),
          confirmFunc: this.onApiAdd
        }
      }

      this.bConfirm = true
    },
    async onApiEdit() {
      const title = this.$t('am_group_edit_notify')
      try {
        this.onSetSaving(true)

        const { id, parent, name } = this.currGroup
        const orgGroup = this.groupInfoList.find((_g) => _g.id === id)

        let param = {
          id,
          name, // 群組名稱
        }
        // 所屬群組 => 如果沒有要改動 父群位置, 就不要帶parent; 避免授權數被清空
        if (parent !== orgGroup.parent) {
          param = { ...param, ...{ parent } }
        }

        // console.log(`[onApiEdit] param:`, param)
        const res = await apiEditGroup(param)
        if (!apiCheckStatus(res)) throw res
        this.$notify({
          type: 'success',
          title,
          message: this.$t('success'),
          // offset: this.notifyOffset
        })
      } catch (err) {
        console.error(`[onApiEdit] fail`, err)
        this.$notify({
          type: 'error',
          title,
          message: this.errorMsg(err),
          // offset: this.notifyOffset
        })
      } finally {
        // 更新群組
        await this.getGroupInfoList()
        await this.getUserTree()
        await this.getGroupList()

        this.$nextTick(() => {
          this.bEdit = false
          this.$emit('set-edit')
          this.onSetSaving(false)
          // this.$emit('select', this.currGroup)
          this.init(this.currGroup) // fake

          if (this.bConfirm) {
            this.confirm = { ...initConfirm }
            this.bConfirm = false
          }
        })
      }
    },
    async onConfirmEdit() {
      // TODO：檢查群組名
      this.confirm = {
        ...this.confirm,
        ...{
          header: this.$t('am_group_edit_notify'),
          title: this.$t('am_group_edit_confirm'),
          confirmFunc: this.onApiEdit
        }
      }

      this.bConfirm = true
    }
  },
  created() {},
  mounted() {
    console.log(`[GroupMainEditGroup.mounted] nodeData:`, this.nodeData)
    this.init(this.nodeData)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.main-edit-group-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .view-info-wrap {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
    // background-color: #f00;
    .view-group-name {
      display: flex;
      justify-content: space-between;
      padding: px2rem(12) 0 px2rem(12) px2rem(12);

      .group-name-wrap {
        flex-grow: 1;
        // background-color: #f00;
        .item-wrap {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;

          &:first-child {
            margin-top: unset;
          }

          .icon {
            img {
              width: 1rem;
              height: 1rem;
            }
          }
          &.parent {
            color: $color_FFC600;
            .label {
              @include font_bold;
            }
          }
          &.child {
            .icon {
              @include filter_FFF;
            }
          }
          .label {
            margin-left: 0.5rem;
            word-break: break-all;
          }
        }
      }
      .group-btn-wrap {
        display: flex;
        // background-color: #00f;
        button {
          margin-left: px2rem(12);
        }
      }
    }
    .view-group-count {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      padding-left: px2rem(12);

      img {
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
        @include filter_FFF;
      }

      .slash {
        margin: 0 0.5rem;
      }
    }
    .view-group-detail {
      margin-top: 0.5rem;
      flex: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      // background-color: #f00;

      .item-wrap {
        padding-left: px2rem(12);

        &:first-child {
          margin-top: unset;
        }
        &:last-child {
          margin-bottom: 0.5rem;
        }
      }

      .edit-item-wrap {
        padding-left: px2rem(12);
        // color: $color_FFF;
      }

      // .tree-value {
      //   // background-color: #f00;
      // }
    }
  }
  .edit-info-wrap {
    overflow-y: scroll;
    overflow-x: auto;
    .edit-item-wrap {
      padding-left: px2rem(12);
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    // margin-top: px2rem(32);
    border-top: 1px solid $color_divider;
    padding: px2rem(12) 0;
    width: 100%;
    .btn {
      margin-right: px2rem(32);
      border: 1px solid transparent;
      border-radius: px2rem(8);
      width: px2rem(114);
      height: px2rem(44);
      color: $color_FFF;

      &:disabled {
        @include disabled;
      }
      &.cancel {
        border-color: $color_E6E6E6;
        &:hover {
          background-color: $color_E6E6E6_10;
        }
      }
      &.confirm {
        color: $color_282942;
        background-color: $color_FFC600;
        &:hover {
          background-color: $color_FFD133;
        }
        &:disabled {
          &:hover {
            background-color: $color_FFC600;
          }
        }
      }
    }
  }
}
</style>
