<template>
  <div class="select-tree-wrap">
    <div class="title-wrap" v-if="title">
      <span>{{ title }}</span>
    </div>
    <button class="label-wrap" ref="label" :disabled="disabled" @click="onOpen">
      <span>{{ valueLabel }}</span>
      <img
        src="@/assets/icons/Drop.svg"
        alt=""
        :class="{ open: showOptions }"
      />
    </button>
    <div class="options-wrap" ref="options" v-show="showOptions">
      <div class="filter-wrap">
        <SearchText
          v-model.trim="filterInput"
          effect="dark"
          @input="onSearchInput"
          @search="onSearchInput"
        />
      </div>
      <div class="pool-wrap">
        <GroupTreeNode
          v-for="(node, idx) in treeData"
          :key="`${node.uiKey}_${idx}`"
          :nodeData="node"
          :layerBase="layerBase"
          :openLayer="maxLayer"
          @select="onSelectGroup"
          :multiple="false"
          :value="currNode ? currNode.id : null"
          :disabledNodeId="disabledGroupId"
          @checked="(node) => null"
          :userCount="false"
          :deviceCount="false"
          :controlQuota="false"
          @plus="(node) => null"
          @minus="(node) => null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getNode } from '@/utils/lib.js'

import SearchText from '@/components/Base/SearchText.vue'
import GroupTreeNode from '@/components/AccountManagement/tools/GroupTreeNode.vue'

export default {
  name: 'SelectTree',
  components: { SearchText, GroupTreeNode },
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    disabledGroupId: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    options: { // tree data
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    exclude: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showOptions: false,
      filterInput: '',
      treeCnt: 0
    }
  },
  computed: {
    treeData() {
      // TODO: filter
      const copyOptions = JSON.parse(JSON.stringify(this.options))
      const ret = this.filterTreeData(copyOptions, this.filterInput)

      // console.log(`[treeData] ret:`, JSON.stringify(ret, ' ', 2))
      return ret
    },
    currNode() {
      if (this.value) {
        const node = getNode(this.options, this.value)

        return node
      }
      return null
    },
    kidNode() {
      return null
    },
    valueLabel() {
      if (this.currNode) {
        return this.currNode.name
      }
      return this.placeholder ? this.placeholder : this.$t('search_placehold')
    },
    layerBase() {
      return this.options[0].layer
    },
    maxLayer() {
      const layer = this.getLayer(this.treeData)
      return layer
    },
  },
  methods: {
    getLayer(options) {
      let layer = 1

      for (const o of options) {
        layer++
        if (o.children.length > 0) {
          return this.getLayer(o.children)
        }
      }

      return layer
    },
    filterTreeData(treeData, key) {
      return treeData.filter((node) => {
        if (node.children) {
          const newKids = this.filterTreeData(node.children, key)
          node.children = newKids
          node.uiOpened = true
          if (newKids.length > 0) return true
        }

        return node.name.toLowerCase().indexOf(key.toLowerCase()) >= 0
      })
    },
    onOpen() {
      this.showOptions = !this.showOptions

      if (this.showOptions) {
        const labelRect = this.$refs.label.getBoundingClientRect()
        this.$nextTick(() => {
          this.$refs.options.style.top = `${labelRect.height}px`

          // TODO: scroll to value
        })
      }
    },
    onSelectGroup(node) {
      this.$emit('input', node.id)
      // console.log(`[SelectTree2.onSelectGroup] `, node.id)
      this.showOptions = false
    },
    onSearchInput() {
      console.log(`[onSearchInput] value:`, this.filterInput)
    }
  },
  mounted() {
    console.log(`[SelectTree2.mounted] options:`, this.options)
  },
  destroyed() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}
.select-tree-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .title-wrap {
    font-size: 0.875rem; // 14px
    color: $color_FFF;
  }
  .label-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0 1rem;
    height: 100%;
    color: $color_black;
    background-color: $color_FFF;

    &[disabled] {
      @include disabled;
    }
    span {
      word-break: break-all;
    }
    img {
      width: 1rem;
      height: 1rem;
      // transform: rotate(0deg);
      @include filter_black;

      &.open {
        transform: rotate(-180deg);
        transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);
      }
    }
  }
  .options-wrap {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;

    border-radius: 0.5rem;
    border: 1px solid $color_4A5C78;
    width: 100%;
    max-height: calc(100vh * 0.4);
    background-color: $color_151B35;
    z-index: 2;

    .filter-wrap:deep {
      position: relative;
      margin: px2rem(12) px2rem(12) 0 px2rem(12);
      width: calc(100% - px2rem(24));
      height: px2rem(36);

      input[type='text'] {
        height: px2rem(36);
      }
    }
    .pool-wrap {
      margin-top: px2rem(12);
      margin-bottom: px2rem(4);
      overflow-y: scroll;
    }
  }
}
</style>
