<template>
  <div class="main-edit-account-wrap">
    <div class="view-info-wrap">
      <div class="view-group-name" :class="{ scrolling }">
        <div class="group-name-wrap">
          <div v-show="parentGroupLabel" class="item-wrap parent">
            <div class="icon">
              <img src="@/assets/icons/group-icon.svg" alt="" />
            </div>
            <span class="label">{{ parentGroupLabel }}</span>
          </div>
          <div class="item-wrap child" :class="{ group: nodeData.uiIsGroup }">
            <div class="icon">
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :disabled="!nodeData.uiImgLabel"
                :content="nodeData.uiImgLabel"
              >
                <img :src="nodeData.uiImg" alt="" />
              </el-tooltip>
            </div>
            <span class="label">{{ nodeLabel }}</span>
          </div>
          <div class="item-wrap enabled">
            <div class="icon">
              <img :src="enableImg" alt="" />
            </div>
            <span class="label">{{ enableLabel }}</span>
          </div>
        </div>
      </div>
      <div class="view-account-wrap" @scroll="onScroll">
        <div
          class="item-wrap"
          v-for="(item, idx) in properties"
          :key="`${item.title}_${idx}`"
          :class="{ title: !item.key }"
        >
          <PropertyItem v-if="!item.key" :title="$t(item.title)" />
          <PropertyItem
            v-else
            :labelIcon="item.icon"
            :label="$t(item.title)"
            :value="itemValue(item)"
            :valueIcon="itemValueIcon(item)"
            :helper="itemHelperIcon(item)"
            :error="null"
            :helperActive="showRolePermission || showGpsMap"
            @helper="onHelper(item)"
          />
        </div>
        <div class="item-wrap last"></div>
      </div>
    </div>
    <PermissionInfo
      v-if="showRolePermission"
      :roleName="nodeData.roleName"
      :permissions="permissions"
      :groupInfoList="groupInfoList"
      @close="showRolePermission = false"
    />
    <PortalMap
      v-if="showGpsMap"
      :gps="nodeData.gps"
      :bEdit="false"
      @close="showGpsMap = false"
      @confirm="showGpsMap = false"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import { mergeNameId } from '@/utils/'
import { userItems, deviceItems, getDeviceModelIcon } from '@/config/account.js'
import PropertyItem from '@/components/AccountManagement/tools/PropertyItem.vue'
import PermissionInfo from '@/components/AccountManagement/tools/PermissionInfo.vue'
import PortalMap from '@/components/AccountManagement/portal/ProtalMap.vue'

export default {
  name: 'GroupMainEditAccount',
  components: {
    PropertyItem,
    PermissionInfo,
    PortalMap
  },
  props: {
    nodeData: {
      type: Object,
      default: null
    },
    parentGroupLabel: {
      type: String,
      default: null
    },
    nodeLabel: {
      type: String,
      default: null
    },
    // layerBase: {
    //   type: Number,
    //   // default: 0
    // }
    permissions: {
      type: Object,
      default: null
    },
    isOut: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showRolePermission: false,
      showGpsMap: false,
      scrolling: false,
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2']),
    ...mapState('userinfo', [
      'deviceModels',
      // 'groupTreeList',
      'groupInfoList',
      'accInfoList'
    ]),
    properties() {
      // console.log(`[properties] this.nodeData:`, this.nodeData)
      // if (!this.nodeDate) return deviceItems
      return this.nodeData.uiIsDevice ? deviceItems : userItems
    },
    enableImg() {
      return this.nodeData.enabled
        ? require('@/assets/icons/success-check.svg')
        : require('@/assets/icons/failed.svg')
    },
    enableLabel() {
      return this.nodeData.enabled ? this.$t('enabled') : this.$t('disabled')
    },
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    // ...mapMutations([]),
    ...mapMutations('userinfo', ['updateAccountPortal']),
    itemValue(item) {
      const { key, title } = item
      const [key1, key2] = key.split('.')
      const value = key2 ? this.nodeData[key1][key2] : this.nodeData[key1]

      // console.log(`[itemValue] ${key}: `, value)
      switch (key) {
        case 'public':
          return this.$t(`account_public_${value}`)
        case 'kind': {
          return title === 'account_from'
            ? value === 1 /*SSO*/
              ? this.$t('account_source_sso')
              : this.$t('account_source_self')
            : this.$t(`account_kind_${value}`)
        }
        case 'role': {
          const { roleName /*, roleId*/ } = this.nodeData
          return roleName
        }
        case 'deviceModelId':
          return this.deviceModels.find((model) => model.id === value)?.name
        case 'defaultSetting':
          return this.$t(`account_defaultSetting_${value}`)
        case '2faMode':
          return this.$t(`account_forced2fa_${value}`)
        case 'watermarkEnabled':
          return this.$t(`account_watermark_${value}`)
        case 'video.keepDay':
          return value + this.$t(value === 1 ? 'one_day' : 'day')
        case 'linkedMode':
          return this.$t(`account_linkmode_${value}`)
        case 'linkedUserId': {
          const acc = this.accInfoList.find((acc) => acc.id === value)
          if (acc) {
            if (title.includes('user')) {
              return mergeNameId(acc?.info?.name, acc.id) // `${acc?.info?.name}(${acc.id})`
            } else if (title.includes('group')) {
              const { groupId } = acc
              const group = this.groupInfoList.find((g) => g.id === groupId)
              return group?.name
            }
          }

          return null
        }
        case 'gps': {
          const { longitude, latitude } = value
          return `${longitude}, ${latitude}`
        }
        case 'language':
          return this.$t(`lang_${value}`)
        case 'lastLogin':
        case 'updatedTime':
        case 'registerTime':
          return moment(value).format('YYYY-MM-DD HH:mm')
        default:
          return value
      }
    },
    itemValueIcon(item) {
      const { key } = item
      const value = this.nodeData[key]

      switch (key) {
        case 'deviceModelId': {
          return require(`@/assets/icons/device_model_${getDeviceModelIcon(
            value
          )}.svg`)
        }
        default:
          return null
      }
    },
    itemHelperIcon(item) {
      const { key } = item

      switch (key) {
        case 'role':
          return require('@/assets/icons/info.svg')
        case 'gps':
          return require('@/assets/icons/account-map.svg')
        default:
          return null
      }
    },
    onScroll($event) {
      if (!this.scrolling) {
        if ($event.target.scrollTop > 0) this.scrolling = true
      }

      if (this.scrolling) {
        if ($event.target.scrollTop === 0) this.scrolling = false
      }
    },
    onHelper(item) {
      console.log(`[onHelper] item:`, item)
      const { key } = item

      switch (key) {
        case 'role':
          this.showRolePermission = true
          break
        case 'gps':
          this.showGpsMap = true
          this.updateAccountPortal(`account-map`)
          break
        default:
      }
    }
  },
  created() {},
  mounted() {
    // console.log(`[GroupMainEditAccount.mounted] nodeData:`, this.nodeData)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.main-edit-account-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // background-color: #f00;

  .view-info-wrap {
    height: 100%;
    .view-group-name {
      // position: fixed;
      display: flex;
      justify-content: space-between;
      padding-top: px2rem(12);
      width: 100%;

      &.scrolling {
        // background-color: $color_151B35_20;
      }
      .group-name-wrap {
        width: 100%;
        // background-color: #f00;

        .item-wrap {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;
          padding-left: px2rem(12);

          &:first-child {
            margin-top: unset;
          }

          .icon {
            display: flex;
            align-items: center;
            img {
              width: 1rem;
              height: 1rem;
            }
          }
          &.parent {
            color: $color_FFC600;
            .label {
              @include font_bold;
            }
          }
          &.child.group {
            .icon {
              @include filter_FFF;
            }
          }
          .label {
            margin-left: 0.5rem;
            word-break: break-all;
          }
        }
      }
    }
    .view-account-wrap {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      flex: 1;
      width: 100%;
      height: calc(100% - px2rem(88)); // 24*3 + 8*2 = 88
      overflow-y: scroll;
      // background-color: #00f;

      .item-wrap {
        padding-left: px2rem(12);

        &:first-child {
          margin-top: unset;
        }
        &:last-child {
          margin-bottom: 0.5rem;
          height: px2rem(12);
        }
      }
    }
  }
}
</style>
