<template>
  <div class="img-switch-wrap">
    <el-tooltip
      v-for="(item, idx) of options"
      :key="`${item.id}_${idx}`"
      popper-class="el-tooltip"
      effect="dark"
      v-delTabIndex
      :disabled="!item.tooltip ? true : false"
      :content="item.tooltip"
    >
      <button
        v-if="realHasBtn(item)"
        class="img-item"
        :class="{ actived: value === item.id }"
        :disabled="item.disabled"
        @click="() => onSwitch(item)"
      >
        <img v-if="item.img" :src="item.img" alt="" />
        <span v-if="item.label" :class="{ 'only-label': !item.img }">
          {{ item.label }}
        </span>
      </button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ImgSwitch',
  components: {},
  props: {
    options: {
      type: Array,
      default() {
        return [
          // {
          //   id: [String, Number], // required
          //   disabled: Boolean, // required
          //   img: require(), // optional; img either or label
          //   label: String, // optional; label either or img
          //   tooltip: String // optional
          // }
        ]
      }
    },
    value: { // 對應 options.id
      type: [String, Number],
      // default:
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    realHasBtn(item) {
      return item.img || item.label
    },
    // ---
    onSwitch(item) {
      if (item.is !== this.value) {
        this.$emit('switch', item.id)
      }
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.img-switch-wrap {
  display: flex;
  align-items: center;
  .img-item {
    padding: 0 1rem;
    height: px2rem(36);
    background-color: $color_FFF_20;

    &:hover {
      background-color: $color_FFF_30;
    }

    &:disabled {
      @include disabled;
      &:hover {
        background-color: $color_FFF_30;
      }
    }

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    &.actived {
      background-color: $color_FFF_50;
    }

    img {
      width: px2rem(24);
      height: px2rem(24);
    }
    span:not(.only-label) {
      margin-left: 0.5rem;
    }
  }
}
</style>
