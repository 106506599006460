<template>
  <fragment @click.prevent>
    <div v-if="!nodeData" class="tree-data-wrap empty">
      <div class="tree-detail-wrap"></div>
      <div class="group-quota-wrap"></div>
    </div>
    <div
      v-else
      class="tree-data-wrap"
      :style="styleObject"
      :class="{ logined: nodeData.uiIsLoginUser, actived: selected }"
      :disabled="uiDisabled"
      @click.stop="() => onSelect(nodeData)"
    >
      <div class="tree-detail-wrap" @click.stop="() => onSelect(nodeData)">
        <button
          v-if="nodeData.uiIsGroup"
          class="collaspe"
          :disabled="uiDisabled || !nodeData.uiIsGroup"
          @click.prevent.stop="onExpand"
        >
          <img :class="{ expand }" src="@/assets/icons/Drop.svg" alt="" />
        </button>
        <button
          v-if="multiple !== null"
          class="checked"
          :disabled="uiDisabled"
          @click.stop="onChecked"
        >
          <img v-if="multiple === false" :src="singleCheckedImg" alt="" />
          <img v-else :src="multipleCheckImg" alt="" />
        </button>
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :disabled="!nodeData.uiImgLabel"
          :content="nodeData.uiImgLabel"
        >
          <img class="icon" :src="nodeData.uiImg" :class="{ online }" alt="" />
        </el-tooltip>
        <span
          class="label"
          :class="{
            group: nodeData.uiIsGroup,
            online,
            locked,
            disabled: accInfoDisabled
          }"
        >
          {{ nodeData.uiLabel }}
        </span>
        <!-- Count -->
        <div v-if="showCount && nodeData.uiIsGroup" class="group-count-wrap">
          <img src="@/assets/icons/group-icon.svg" alt="" />
          <span>{{ nodeData.uiKid.group }}</span>
          <template v-if="userCount">
            <span class="slash">/</span>
            <img src="@/assets/icons/user.svg" alt="" />
            <span>{{ nodeData.uiKid.user }}</span>
          </template>
          <template v-if="deviceCount">
            <span class="slash">/</span>
            <img src="@/assets/icons/device_model_0.svg" alt="" />
            <span>{{ nodeData.uiKid.device }}</span>
          </template>
          <template v-if="isDevMode">
            <span class="slash">/</span>
            <img src="@/assets/icons/openVolume.svg" alt="" />
            <span>{{ nodeData.maxIn /*發布*/ }}</span>
            <span class="slash">/</span>
            <img src="@/assets/icons/openEye.svg" alt="" />
            <span>{{ nodeData.maxOut /*收看*/ }}</span>
          </template>
        </div>
      </div>
      <!-- Quota -->
      <div v-if="controlQuota" class="group-quota-wrap">
        <span v-if="!nodeData.uiIsGroup">{{ /*'ACCOUNT'*/ }}</span>
        <template v-else>
          <div class="quota-value-wrap">
            <div class="value using" :class="{ 'not-empty': quotaUsing > 0 }">
              <span>{{ quotaUsing }}</span>
            </div>
            <span class="slash">{{ '/' }}</span>
            <div class="value divided">
              <span>{{ quotaDivided }}</span>
            </div>
            <span class="slash">{{ '/' }}</span>
            <div class="value quota">
              <span>{{ quotaValue }}</span>
            </div>
          </div>
          <div class="control-btn-wrap">
            <SmallBtn
              class="btn"
              :style="{
                visibility: addAndMinusVisibility
              }"
              :disabled="nodeData.uiIsLoginUser"
              :img="require('@/assets/icons/minus.svg')"
              @click="() => onMinus(nodeData)"
            />
            <SmallBtn
              class="btn"
              :style="{
                visibility: addAndMinusVisibility
              }"
              :disabled="nodeData.uiIsLoginUser"
              :img="require('@/assets/icons/plus.svg')"
              @click="() => onPlus(nodeData)"
            />
          </div>
        </template>
      </div>
    </div>
    <template v-if="expand /*用 v-show 會無法收合...*/">
      <GroupTreeNode
        v-for="(node, idx) in nodeData.children"
        :key="`${node.uiKey}_${idx}`"
        :nodeData="node"
        :layerBase="layerBase"
        :openLayer="openLayer"
        :value="value"
        :multiple="multiple"
        @select="onSelect"
        :disabledNodeId="disabledNodeId"
        :userCount="userCount"
        :deviceCount="deviceCount"

        :controlQuota="controlQuota"
        :isOut="isOut"
        :bEdit="bEdit"
        @plus="onPlus"
        @minus="onMinus"
      />
    </template>
  </fragment>
</template>

<script>
import { isDevMode } from '@/utils/lib.js'
import SmallBtn from '@/components/Base/SmallBtn.vue'

export default {
  name: 'GroupTreeNode',
  components: { SmallBtn },
  props: {
    nodeData: {
      type: Object,
      require: true,
      // default: null
    },
    // filter: {
    //   type: String,
    //   default: null
    // },
    layerBase: {
      type: Number,
      // default: 0
    },
    openLayer: {
      type: Number,
      default: 2
    },
    multiple: {
      type: [Boolean, null],
      default: null
    },
    value: {
      type: [Array, String],
      default() {
        return [] || ''
      }
    },
    disabledNodeId: {
      type: String,
      default: null
    },
    // 顯示數量 ---
    userCount: {
      type: Boolean,
      deault: false
    },
    deviceCount: {
      type: Boolean,
      deault: false
    },
    // 配額控制 ---
    controlQuota: {
      type: Boolean,
      deault: false
    },
    isOut: {
      type: Boolean,
      deault: false
    },
    bEdit: {
      type: Boolean,
      deault: false
    }
  },
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    isDevMode() {
      return isDevMode()
    },
    // style
    styleObject() {
      if (!this.nodeData) {
        return { '--leftGap': `0.5rem` }
      }
      const gap = /*0.5 +*/ 1.5 * (this.nodeData.uiLayer - this.layerBase)

      return { '--leftGap': `${gap}rem` }
    },
    singleCheckedImg() {
      return this.selected
        ? require(`@/assets/icons/radio-check.svg`)
        : require(`@/assets/icons/circle.svg`)
    },
    multipleCheckImg() {
      return require(`@/assets/icons/uncheck.svg`)
      // return require(`@/assets/icons/checkbox-yellow-checked.svg`)
      // return require(`@/assets/icons/tree-indeterminate-yellow.svg`)
    },
    online() {
      if (!this.nodeData.uiIsGroup) {
        return this.nodeData.uiOnline
      }
      return false
    },
    locked() {
      if (!this.nodeData.uiIsGroup) {
        return this.nodeData.locked ? true : false
      }
      return false
    },
    accInfoDisabled() {
      if (!this.nodeData.uiIsGroup) {
        return this.nodeData.enabled ? false : true
      }
      return false
    },

    // quota ---
    isRoot() {
      return this.nodeData.layer === this.layerBase
    },
    addAndMinusVisibility() {
      return this.bEdit ? (!this.isRoot ? 'visible' : 'hidden') : 'hidden'
    },
    quotaType() {
      return this.isOut ? 'maxOut' : 'maxIn'
    },
    quotaUsing() {
      if (!this.controlQuota) return 0

      return this.getQuotaUsing(this.nodeData)
    },
    quotaDivided() {
      if (!this.controlQuota) return 0

      return this.getQuotaDivided(this.nodeData, this.quotaType)
    },
    quotaValue() {
      if (!this.controlQuota) return 0

      return this.nodeData[this.quotaType]
    },

    // ---
    showCount() {
      return this.userCount || this.deviceCount
    },
    selected() {
      if (!this.value) return false
      return this.value === this.nodeData.id
    },
    uiDisabled() {
      if (!this.disabledNodeId) return false
      return this.disabledNodeId === this.nodeData.id
    }
  },
  watch: {
    // nodeData: {
    //   deep: true,
    //   handler() {
    //     console.log(`[GroupTreeNode.W.nodeData] ${this.nodeData.name} nodeData.${this.quotaType}:`, this.nodeData[this.quotaType])
    //   }
    // }
  },
  methods: {
    initExpand() {
      if (!this.nodeData) return

      // 展開處理
      const base = this.nodeData.uiLayer - this.layerBase

      if (base <= this.openLayer) {
        this.onExpand()
      }
    },
    init() {
      this.initExpand()
    },
    getQuotaUsing(node) {
      let using = 0

      if (node.children && node.children.length > 0) {
        for (const kid of node.children) {
          using += this.getQuotaUsing(kid)
        }
      } else {
        if (!node.uiIsGroup && node.uiOnline) {
          using++
        }
      }

      return using
    },
    getQuotaDivided(node, key) {
      // 計算子群使用量
      if (!node.uiIsGroup) return

      let distributed = 0
      if (
        node.children &&
        // node.children.length > 0 &&
        node.children.filter((kid) => kid.uiIsGroup).length > 0
      ) {
        // node
        // 算出下一層(=子層) (不含下下層) maxIn 或 maxOut
        node.children.forEach((kid) => {
          distributed += kid[key] ? Number(kid[key]) : 0
        })
      } else {
        // leaf
        distributed = node[key] ? Number(node[key]) : 0
      }

      return distributed
    },
    // ---
    // onClickSelect() {
    //   if (this.uiDisabled) return

    //   this.$emit('select', this.nodeData)
    // },
    onSelect(node) {
      if (this.uiDisabled) return

      this.$emit('select', node)
    },
    onExpand() {
      if (this.uiDisabled) return

      this.expand = !this.expand
    },
    onChecked() {
      if (this.multiple === false) {
        // 單選
        this.$emit('select', this.nodeData)
      }
      // else if (this.multiple === true) {} // TODO:多選
      this.$emit('input', this.nodeData)
    },
    onPlus(node) {
      this.$emit('plus', node)
    },
    onMinus(node) {
      this.$emit('minus', node)
    }
  },
  created() {},
  mounted() {
    // console.log(`[GroupTreeNode.mounted] node:`, this.nodeData)
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
  // border-image-width: unset;
}

// fragment {
//   border-image-width: 0;
// }

.tree-data-wrap {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  // margin-top: px2rem(-2); // TODO: quota
  // margin-bottom: px2rem(-4);
  border-image-width: 0px !important;
  width: 100%;

  &.empty {
    // height: 100%;
    flex-grow: 1;
    // background-color: #f00;
  }

  &:hover:not([disabled]):not(.empty) {
    background-color: $color_6E7D93_20;
    .quota span {
      border-bottom: 1px solid $color_FFF;
    }
  }

  &.logined {
    background-color: $color_FFE99F_20;
    &:hover {
      background-color: $color_FFE99F_20;
    }
  }

  &:is(.logined).actived {
    background-color: $color_FFE99F_40;
    &:hover {
      background-color: $color_FFE99F_40;
    }
  }

  &:not(.logined).actived {
    background-color: $color_6E7D93_60;
    &:hover {
      background-color: $color_6E7D93_60;
    }
  }

  &[disabled] {
    @include disabled;
  }

  .tree-detail-wrap {
    display: flex;
    align-items: center;
    margin-left: var(--leftGap);
    .collaspe {
      display: flex;
      // background-color: #f00;

      &:hover:not([disabled]) {
        cursor: pointer;
      }

      img {
        margin: px2rem(6) px2rem(9) px2rem(6) px2rem(1);
        width: 0.875rem; // 14px
        height: 0.875rem; // 14px
        transform: rotate(-90deg);
        transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

        &.expand {
          transform: rotate(0deg);
        }
      }
    }

    .checked {
      display: flex;
      // background-color: #00f;

      &:hover:not([disabled]) {
        cursor: pointer;
      }

      img {
        margin: px2rem(6) px2rem(8) px2rem(6) 0;
        width: 1rem;
        height: 1rem;
      }
    }
    img.icon {
      margin: px2rem(6) px2rem(8) px2rem(6) 0;
      width: 1rem;
      height: 1rem;

      // &.group {
      //   @include filter_group;
      // }
      &.online {
        @include filter_online;
      }
    }
    .label {
      color: $color_FFF;
      font-size: 1rem;
      word-break: break-all;
      &.group {
        @include group_name;
      }

      &.online {
        color: $color_online;
      }
      &.disabled {
        @include disabled;
      }
      // &.locked {
      //   color: $color_locked;
      // }
    }

    // Count
    .group-count-wrap {
      display: flex;
      align-items: center;
      margin-left: px2rem(4);
      font-size: 0.875rem; // 14px
      color: $color_FFF_50;

      img {
        margin-right: px2rem(2);
        width: px2rem(12);
        height: px2rem(12);
        @include filter_FFF_50;
      }
      .slash {
        margin-left: px2rem(2);
        margin-right: px2rem(2);
      }
    }
  }

  // Quota
  .group-quota-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 1px solid $color_divider;
    padding-left: 0.75rem;
    width: 50%;
    // height: 100%;
    // background-color: #f00;
    .quota-value-wrap {
      display: flex;
      flex-grow: 1;
      // background-color: #00f;
      div.value {
        min-width: px2rem(80);
        text-align: center;
      }
      .using.not-empty {
        color: $color_online;
      }
      .slash {
        margin: 0 0.5rem;
      }
    }
    .control-btn-wrap:deep {
      display: flex;
      // background-color: #0ff;
      .btn {
        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
</style>
