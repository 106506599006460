<template>
  <div class="main-edit-wrap">
    <GroupMainEditGroup
      v-if="nodeData.uiIsGroup"
      :staff="staff"
      :allowEdit="allowEdit"
      :nodeData="nodeData"
      :parentGroupLabel="parentGroupLabel"
      :nodeLabel="nodeLabel"
      @set-edit="$emit('set-edit')"
      @update_edit="onUpdateEditSt"
    />
    <GroupMainEditAccount
      v-else
      :nodeData="nodeData"
      :parentGroupLabel="parentGroupLabel"
      :nodeLabel="nodeLabel"
      :permissions="rolePermission"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import { mergeNameId } from '@/utils/'
import { PermissionLevel } from '@/config/permissions.js'
// import SmallTextBtn from '@/components/Base/SmallTextBtn.vue'
import GroupMainEditGroup from '@/components/AccountManagement/group/Edit/GroupMainEditGroup.vue'
import GroupMainEditAccount from '@/components/AccountManagement/group/Edit/GroupMainEditAccount.vue'

export default {
  name: 'GroupMainEdit',
  components: {
    GroupMainEditGroup,
    GroupMainEditAccount,
  },
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    nodeData: {
      type: Object,
      default: null
    },
    layerBase: {
      type: Number,
      // default: 0
    }
  },
  data() {
    return {
      // bSave: false
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2']),
    ...mapState('userinfo', ['groupTreeList', 'groupInfoList', 'roleList']),
    allowEdit() {
      return this.permissionV2.group > PermissionLevel.L1
    },
    userInfo() {
      return this.getUserInfo
    },
    parentGroupLabel() {
      if (!this.nodeData) return null

      const { uiIsGroup, parent, groupId } = this.nodeData
      let parentGroupId = null

      if (uiIsGroup) {
        if (this.nodeData.uiLayer === this.layerBase) {
          parentGroupId = null // 最高階不顯示 父群
        } else {
          parentGroupId = Number(parent) ? parent : null
        }
      } else {
        parentGroupId = groupId
      }

      if (!parentGroupId) return null

      const group = this.groupInfoList.find(
        (gInfo) => gInfo.id === parentGroupId
      )
      return group ? group.name : ''
    },
    nodeLabel() {
      if (!this.nodeData) return null

      const { uiIsGroup, name, uiIsDevice, info, video, id } = this.nodeData
      let label = uiIsGroup
        ? name
        : mergeNameId(uiIsDevice ? video.title : info.name, id) // `${uiIsDevice ? video.title : info.name}(${id})`

      return label
    },
    rolePermission() {
      if (!this.nodeData.uiIsGroup && !this.nodeData.uiIsDevice) {
        return this.roleList.find((role) => role.id === this.nodeData.roleId)
          ?.permissionV2
      }
      return null
    }
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    onUpdateEditSt(status) {
      this.$emit('update_edit', status)
    },
    // onSelect(node) {
    //   this.$emit('select', node)
    // }
  },
  created() {},
  mounted() {
    console.log(`[GroupMainEdit.mounted] nodeData:`, this.nodeData)
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.main-edit-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  margin-bottom: 0.125rem;
  // padding-top: px2rem(12);
}
</style>
