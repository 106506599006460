<template>
  <div class="group-quato-wrap">
    <div class="tree-quato-view">
      <div class="tab-wrap">
        <TabList
          :tabList="tabList"
          :tabIdx="tabIdx"
          :disabledList="disabledTabList"
          @select="onSelectTab"
        ></TabList>
      </div>
      <div class="horzontal-divider"></div>

      <div class="content-wrap">
        <div class="control-wrap">
          <div class="control-top">
            <div class="control-top-left">
              <ToggleSwitch
                id="quota-online-switch"
                :suffix="toggleLabel"
                :content="toggleLabel"
                :disabled="editing || disabledAction"
                :value="bOnline"
                @switch="onSwitch"
              />
            </div>
            <div class="control-top-right">
              <div class="control-title-wrap">
                <span class="using">{{ $t('am_quota_using_value') }}</span>
                <span class="slash">{{ '/' }}</span>
                <span class="divided">{{ $t('am_quota_divided_value') }}</span>
                <span class="slash">{{ '/' }}</span>
                <span class="quota">{{ $t('am_quota_max_value') /*'總分配'*/ }}</span>
              </div>
              <SmallTextBtn
                class="control-edit-btn"
                :style="{ visibility: bQuotaEdit ? 'hidden' : 'visible' }"
                :label="$t('edit') /*編輯*/"
                :disabled="editing || disabledAction"
                @click="onEdit"
              />
            </div>
          </div>
          <div class="control-tree" ref="tree">
            <GroupTreeNode
              v-for="(node, idx) in uiTreeData"
              :key="`${node.id}_${tabIdx}_${uiOpenLayer}_${idx}` /*區隔元件*/"
              :nodeData="node"
              :layerBase="layerBase"
              :openLayer="uiOpenLayer"
              @select="onSelectNode"
              :multiple="null"
              :value="currNode ? currNode.id : null"
              :disabledNode="null"
              @checked="(node) => null"
              :userCount="isOut"
              :deviceCount="!isOut"
              :controlQuota="true"
              :isOut="isOut"
              :bEdit="bQuotaEdit"
              @plus="onPlus"
              @minus="onMinus"
            />
            <!-- 空元件填滿剩餘空間 -->
            <GroupTreeNode :nodeData="null" :controlQuota="true" />
          </div>
        </div>
        <div v-if="bQuotaEdit" class="footer-wrap">
          <div class="block-wrap"></div>
          <div class="block-wrap ctrl-btn">
            <button class="btn cancel" :disabled="false" @click="onCalcelEdit">
              {{ $t('cancel') }}
            </button>
            <button
              class="btn confirm"
              :disabled="false"
              @click="onConfirmEdit"
            >
              {{ $t('confirm') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="vertical-divider"></div>
    <div class="detail-view">
      <GroupQuotaEdit
        v-if="currNode"
        :staff="staff"
        :nodeData="currNode"
        :rawTreeData="rawTreeData /*需要完整 treeData, 過濾完的會找不到資料*/"
        :layerBase="layerBase"
        :isOut="isOut"
        :editing="editing"
        @update_edit="onUpdateEditSt"
        @set-edit="$emit('set-edit')"
        @get-current="onUpdateCurrNode"
      />
    </div>
    <MessageModal
      v-if="bConfirm"
      :header="confirm.header"
      :title="confirm.title"
      :btns="confirm.btns"
      @close="bConfirm = false"
      @cancel="bConfirm = false"
      @confirm="confirm.confirmFunc"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import { isUser, isDevice } from '@/utils/global.js'
import { getNode } from '@/utils/lib.js'
import {
  apiEditGroup,
  apiCheckStatus,
  apiErrorMsg
} from '@/api/index.js'

import TabList from '@/components/Base/TabList.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
// import ToggleSwitch from '@/components/Base/ToggleSwitch.vue'
import GroupQuotaEdit from '@/components/AccountManagement/group/Edit/GroupQuotaEdit.vue'
import GroupTreeNode from '@/components/AccountManagement/tools/GroupTreeNode.vue'
import SmallTextBtn from '@/components/Base/SmallTextBtn.vue'
// import GroupTreeGrid from '@/components/AccountManagement/tools/GroupTreeGrid.vue'
import MessageModal from '@/components/Base/MessageModal.vue'

const QuotaTab = {
  in: 0, // 發布
  out: 1, // 收看
}

const initConfirm = {
  header: null,
  title: null,
  btns: { cancel: true, confirm: true },
  confirmFunc: () => null,
}

export default {
  name: 'GroupQuota',
  components: {
    GroupTreeNode,
    // GroupTreeGrid,
    TabList,
    ToggleSwitch,
    SmallTextBtn,
    GroupQuotaEdit,
    MessageModal,
  },
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default() {
        return []
      }
    },
    rawTreeData: {
      type: Array,
      default() {
        return []
      }
    },
    layerBase: {
      type: Number,
      // default: 0
    },
    openLayer: {
      type: Number,
      // default: 2
    },
    fullLayer: {
      type: Number,
      // default: 0
    },
    editing: {
      type: Boolean,
      default: false
    },
    quotaFilter: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      tabIdx: QuotaTab.in, // 第一個
      currNode: null,
      bOnline: false,
      bSave: false,
      bQuotaEdit: false,

      uiTreeData: [],
      orgUiTreeData: [],

      bConfirm: false,
      confirm: { ...initConfirm }
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState('userinfo', ['groupInfoList']),
    ...mapState('userinfo/group', ['filterText']),
    tabList() {
      let list = []
      const labels = Object.keys(QuotaTab) // ['收看'=out, '發布'=in]
      list = labels.map((label) => {
        return {
          id: QuotaTab[label],
          // icon,
          label: this.$t(`am_group_quota_${label}`),
          // count: 10
        }
      })

      return list
    },
    disabledTabList() {
      if (!this.editing) return []

      return this.tabList.filter((tab) => tab.id !== this.tabIdx).map((tab) => tab.id)
    },
    isOut() {
      return this.tabIdx === QuotaTab.out ? true : false
    },
    quotaType() {
      return this.isOut ? 'maxOut' : 'maxIn'
    },
    toggleLabel() {
      return this.$t('am_group_online_account') // 使用中帳號
      // return this.isOut ? '收看中' : '發布中'
    },
    uiOpenLayer() {
      // console.log(`[GroupQuota] fullLayer, openLayer:`, this.fullLayer, this.openLayer)
      if (this.filterText || this.quotaFilter.length > 0) {
        return this.fullLayer
      }

      // bOnline 是 true 時, 要全開
      if (this.bOnline) {
        return this.fullLayer
      }

      return this.openLayer
    },
    disabledAction() {
      return this.uiTreeData.length <= 0
    },
  },
  watch: {
    tabIdx() {
      this.initUiTreeData(this.treeData)
    },
    // filterText() {
    //   this.initUiTreeData(this.treeData)
    // },
    bOnline() {
      // console.log(`[GroupQuota.W.bOnline]`, this.bOnline)
      this.initUiTreeData(this.treeData)
    },
    treeData: {
      deep: true,
      handler() {
        // console.log(`[GroupQuota.W.treeData]`)
        this.initUiTreeData(this.treeData)
      }
    }
  },
  methods: {
    ...mapActions('userinfo', ['fetchAccountManageList']), // API query and update
    ...mapMutations([]),
    initUiTreeData(treeData) {
      this.uiTreeData = treeData ? JSON.parse(JSON.stringify(treeData)) : []
      this.filterTreeData(this.uiTreeData, this.isOut ? isUser : isDevice)
    },
    init() {
      this.initUiTreeData(this.treeData)
    },
    filterTreeData(tmpTreeData, filterFunc) {
      // const filterFunc = this.isOut ? isUser : isDevice
      for (const node of tmpTreeData) {
        if (node.children && node.children.length > 0) {
          const newChildren = node.children?.filter((node) => {
            if (node.uiIsGroup) return true
            if (filterFunc(node.kind)) {
              return this.bOnline ? node.uiOnline : true
            }
            return false
          })
          node.children = [...newChildren]
          if (this.bOnline && newChildren && newChildren.length > 0) {
            node.uiOpened = true
          }

          this.filterTreeData(node.children, filterFunc)
        }
      }
      return tmpTreeData
    },

    onSelectTab(tIdx) {
      if (this.tabIdx !== tIdx) {
        this.tabIdx = tIdx
        this.$emit('set-quota-type', Object.keys(QuotaTab)[this.tabIdx])

        // 清除 使用中帳號 filter
        this.bOnline = false
        // 清除 currNode
        this.currNode = null
        // 清除 bQuotaEdit 狀態
        this.bQuotaEdit = false
      }
    },
    onSelectNode(node) {
      if (this.editing) return

      this.currNode =
        this.currNode && this.currNode.id === node.id ? null : node
    },
    onSwitch(value) {
      this.bOnline = value
      // console.log(`[onSwitch] value, bOnline:`, value, this.bOnline)
    },
    onUpdateEditSt(status) {
      console.log(`[授權.onUpdateEditSt]`)
      this.bSave = status
    },
    onUpdateCurrNode() {
      const newCurrNode = getNode(this.treeData, this.currNode.id)

      this.currNode = { ...newCurrNode }
    },
    onEdit() {
      this.bQuotaEdit = true //!this.bQuotaEdit
      this.$emit('set-edit')
    },
    onCalcelEdit() {
      // console.log(`[GroupQuota.onCalcelEdit]`)
      this.bQuotaEdit = false
      this.initUiTreeData(this.treeData)
      this.$emit('set-edit')
    },
    getModifiedGroup() {
      const modified = []

      for (const group of this.groupInfoList) {
        const nodeGroup = getNode(this.uiTreeData, group.id)

        if (!nodeGroup) continue
        if (nodeGroup.uiIsGroup && nodeGroup[this.quotaType] !== group[[this.quotaType]]) {
          modified.push(nodeGroup)
        }
      }

      // console.log(`[getModifiedGroup] modified:`, modified)
      return modified
    },
    async onApiEdit(groupList) {
      const title = this.$t('am_quota_edit' /*編輯授權*/)
      const resList = []
      // console.log(`[onApiEdit] groupList:`, groupList)
      try {
        for (const group of groupList) {
          let param = {
            id: group.id,
            [this.quotaType]: group[this.quotaType], // 授權數
          }
          // console.log(`[onApiEdit] param:`, param)
          const res = await apiEditGroup(param)
          if (apiCheckStatus(res)) {
            setTimeout(() => {
              this.$notify({
                type: 'success',
                title,
                message: this.$t('success')
              })
            })
            resList.push(true)
          } else {
            setTimeout(() => {
              this.$notify({
                type: 'error',
                title,
                message:
                  this.$t('fail') + res
                    ? this.$t(apiErrorMsg(res))
                    : this.$t('api_error')
              })
            })
            resList.push(false)
          }

          if (resList.filter((res) => !res).length > 0) throw resList
        }
      } catch (err) {
        console.error(`[onApiEdit] fail.`, err)
        this.$notify({
          type: 'error',
          title,
          message: this.$t('fail')
        })
      } finally {
        this.confirm = { ...initConfirm }
        this.bConfirm = false
        this.fetchAccountManageList()
      }
    },
    async onConfirmEdit() {
      const title = this.$t('am_quota_edit' /*編輯授權*/)
      try {
        // 從 uiTreeData & treeData 過濾出需要更新的群組
        const mGroupList = this.getModifiedGroup()
        console.log(`[onConfirmEdit] mGroupList:`, mGroupList)

        if (mGroupList.length <= 0) {
          this.$notify({
            type: 'warning',
            title,
            message: this.$t('nothing_modified' /*未執行任何更改*/)
          })
          return
        }

        this.confirm = {
          ...this.confirm,
          ...{
            header: title,
            title: this.$t('am_quota_edit_confirm' /*您確定要修改群組授權嗎？*/),
            confirmFunc: () => this.onApiEdit(mGroupList)
          }
        }

        this.bConfirm = true
      } catch (err) {
        console.error(`[GroupQuota] onConfirmEdit fail`, err)
        this.$notify({
          type: 'error',
          title,
          message: err
        })
      } finally {
        this.bQuotaEdit = false
        this.$emit('set-edit')
        // TODO：取最新 groupInfoList => 在 onApiEdit 取得
      }
    },

    onSelectGroup() {
      console.log(`[GroupQuota.onSelectGroup] currNode:`, this.currNode.id)
    },
    onSelectAcc() {
      console.log(`[GroupQuota.onSelectAcc] currNode:`, this.currNode.id)
    },
    onRowChecked(node) {
      console.log(`[GroupQuota.onRowChecked] node:`, node.id)
      this.currNode = JSON.parse(JSON.stringify(node))

      node.uiIsGroup ? this.onSelectGroup : this.onSelectAcc
    },

    // 增加/刪除 授權
    getFamily(node) {
      // 父層 ---
      const parentGroup = getNode(this.uiTreeData, node.parent)
      const parentQuota = parentGroup ? parentGroup[this.quotaType] : 0

      // 同層 ---
      const siblingGroups = !parentGroup.children
        ? []
        : parentGroup.children.filter((_g) => _g.uiIsGroup)

      let usedQuota = 0
      for (const siblingG of siblingGroups) {
        usedQuota += siblingG[this.quotaType]
      }

      // 子層 ---
      const kidGroups = !node.children
        ? []
        : node.children.filter((_g) => _g.uiIsGroup)

      let kidQuota = 0
      for (const kidG of kidGroups) {
        kidQuota += kidG[this.quotaType]
      }

      return {
        parentGroup, parentQuota, // 父層
        siblingGroups, usedQuota, // 同層
        kidGroups, kidQuota // 子層
      }
    },
    onPlus(node) {
      const title = this.$t('am_quota_edit') // '增加授權'
      let message = null

      const tmpNode = node
      const { parentGroup, parentQuota, usedQuota } = this.getFamily(tmpNode)

      // 父群是否為0
      if (parentQuota <= 0) {
        message = this.$t(
          'am_quota_parent_group_auth_empty',
          { name: parentGroup.name }
          /*`上層群組 ${parentGroup.name} 未設定授權`*/
        )
        this.$notify({ type: 'warning', title, message })
        return
      }

      // 計算父群是否已滿
      if (usedQuota >= parentQuota) {
        message = this.$t(
          'am_quota_parent_group_auth_full',
          { name: parentGroup.name }
          /*`上層群組 ${parentGroup.name} 授權已滿`*/
        )
        this.$notify({ type: 'warning', title, message })
        return
      }

      // 增加授權
      const orgQuota = node[this.quotaType]
      const value = orgQuota + 1
      node[this.quotaType] = value

      // DEBUG
      const newNode = getNode(this.uiTreeData, node.id)
      console.log(`[GroupQuota.onPlus] ${node.name} newNode.${this.quotaType}:`, newNode[this.quotaType])
    },
    onMinus(node) {
      const title = this.$t('am_quota_edit') // '刪減授權'
      let message = null

      const { kidQuota } = this.getFamily(node)

      // 自己是否為0
      if (node[this.quotaType] <= 0) {
        message = this.$t(
          'am_quota_auth_not_enough',
          { name: node.name }
          /*`${node.name} 授權不足`*/
        )
        this.$notify({ type: 'warning', title, message })
        return
      }

      // 子群是否已分完
      if (kidQuota > 0 && kidQuota >= node[this.quotaType]) {
        message = this.$t(
          'am_quota_auth_full',
          { name: node.name }
          /*`${node.name} 授權已分完, 無法刪減`*/
        )
        this.$notify({ type: 'warning', title, message })
        return
      }

      // 刪減授權
      const orgQuota = node[this.quotaType]
      const value = orgQuota - 1
      node[this.quotaType] = value

      // DEBUG
      const newNode = getNode(this.uiTreeData, node.id)
      console.log(`[GroupQuota.onMinus] ${node.name} newNode.${this.quotaType}:`, newNode[this.quotaType])
    }
  },
  // created() {},
  mounted() {
    this.init()
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$QuatoW: calc(764 / 1230 * 100%);
$TabH: px2rem(calc(12 + 36 + 1));
$DetailW: calc(100% - $QuatoW);
$CtrlH: px2rem(52);

* {
  box-sizing: border-box;
  // user-select: none;
}
.horzontal-divider {
  @include horzontal_divider;
}
.vertical-divider {
  @include vertical_divider;
}
.group-quato-wrap {
  display: flex;
  height: 100%;
  .tree-quato-view {
    display: flex;
    flex-direction: column;
    width: $QuatoW;
    .tab-wrap {}

    .content-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - $TabH);
      // flex-grow: 1;

      .control-wrap {
        flex-grow: 1;
        width: 100%;
        height: 50%;
        // overflow-y: scroll;
        // background-color: #f00;

        .control-top {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .control-top-left:deep {
            display: flex;
            align-items: center;
            border-right: 1px solid $color_divider;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            width: calc(50% - 0.35rem);

            .toggle-switch-wrap {
              justify-content: unset;
            }
            label,
            .label.suffix {
              font-size: 1rem;
            }
            label {
              &.disabled {
                @include disabled;
              }
            }
          }
          .control-top-right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // border-left: 1px solid $color_divider;
            padding: 0.5rem 1rem 0.5rem 0.5rem;
            width: 50%; // calc(50% + 0.75rem);
            overflow: hidden;

            .control-title-wrap {
              display: flex;
              flex-grow: 1;
              // background-color: #f00;
              span:not(.slash) {
                width: px2rem(80);
                text-align: center;
              }
              .slash {
                margin: 0 0.5rem;
                // width: px2rem(4);
              }
            }
            .control-edit-btn {}
          }
        }
        .control-tree {
          // flex-grow: 1;
          display: flex;
          flex-direction: column;
          height: calc(100% - px2rem(52)); // 36 + 16
          overflow-y: scroll;
        }
      }

      .footer-wrap {
        display: flex;
        border-top: 1px solid $color_divider;
        width: 100%;

        .block-wrap {
          display: flex;
          padding: px2rem(12) 0;
          justify-content: center;
          width: 50%;
          // background-color: #00f;
          // border-right: 1px solid #f00;

          &.ctrl-btn {
            // border-top: 1px solid $color_4A5C78;
            // border-left: 1px solid $color_4A5C78;
          }
          .btn {
            margin-right: px2rem(32);
            border: 1px solid transparent;
            border-radius: px2rem(8);
            width: px2rem(114);
            height: px2rem(44);
            color: $color_FFF;

            &:last-child {
              margin-right: unset;
            }
            &.cancel {
              border-color: $color_E6E6E6;
              &:hover {
                background-color: $color_E6E6E6_10;
              }
            }
            &.confirm {
              color: $color_282942;
              background-color: $color_FFC600;
              &:hover {
                background-color: $color_FFD133;
              }
            }
          }
        }
      }
    }
  }
  .detail-view {
    width: $DetailW;
  }
}
</style>
