<template>
  <div
    class="input-text-wrap"
    :style="{ '--height': height }"
    :disabled="disabled"
  >
    <input
      type="text"
      v-model="uiValue"
      :maxlength="maxlength"
      :disabled="disabled"
    />
    <span
      class="len-info"
      :class="{ warn: vertifyStrLen(uiValue, warnLength) }"
    >
      {{ `${!uiValue ? 0 : uiValue.length}/${maxlength}` }}
    </span>
  </div>
</template>

<script>
import { vertifyStrLen } from '@/utils/lib.js'

export default {
  name: 'InputText',
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 64 // 字串中, 最大值
    },
    warnLimit: {
      type: Number,
      // default: ,
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    uiValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    warnLength() {
      return this.warnLimit ? this.warnLimit : this.maxlength
    }
  },
  watch: {},
  methods: {
    vertifyStrLen
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$GapW: 1rem; // px2rem(20);
* {
  box-sizing: border-box;
  // user-select: none;
}
.input-text-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: $GapW;
  height: var(--height);
  width: 100%;

  &[disabled] {
    @include disabled;
  }

  input[type='text'] {
    margin-left: $GapW;
    height: 100%;
    width: calc(100% - 1rem - 2 * $GapW);
    font-size: 1rem;
    color: $color_black;
    // &:[disabled] {}
    &:focus {
      // background-color: $color_E6E6E6; // for debug
    }
  }
  .len-info {
    font-size: 0.875rem; // 14px
    color: $color_black_50;
    &.warn {
      color: $color_warn;
    }
  }
}
</style>
