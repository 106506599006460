<template>
  <div class="acc-group-wrap">
    <TopNav
      :navList="navList"
      :isSubNav="true"
      :disabled="editing"
      v-model="tabView"
      @goTo="onSelectTab"
    />
    <div class="search-wrap">
      <SearchText
        class="search-input"
        v-model.trim="grpFilterText"
        :disabled="editing"
        :placeholder="$t('search')"
      />
      <Select
        v-if="isQuota"
        class="quato-mode"
        v-model="quotaFilter"
        effect="dark"
        :options="quotaFilterOptions"
        :multiSelect="true"
        :hasFilter="quotaFilter.length > 0"
        :isDisabled="editing"
        :placeholder="$t('an_group_quota_filter') /*授權狀態*/"
        :placement="'top'"
        :class="{ disabled: editing || groupInfoList.length <= 0 }"
      />
      <el-tooltip
        popper-class="el-tooltip"
        effect="dark"
        v-delTabIndex
        :visible-arrow="false"
        :content="$t('search_clear')"
        placement="top"
      >
        <SmallBtn
          class="eraser"
          :img="require('@/assets/icons/erase-white.svg')"
          :disabled="editing"
          @click="onEraser"
        />
      </el-tooltip>
      <span class="count">{{ $t('am_group_count', { count: groupLen }) }}</span>
    </div>
    <div class="divider"></div>
    <div class="content">
      <transition>
        <component
          :is="tabView"
          :staff="staff ? true : false"
          :treeData="treeData"
          :rawTreeData="rawTreeData"
          :layerBase="layerBase"
          :openLayer="openLayer"
          :fullLayer="fullLayer"
          :editing="editing"
          :quotaFilter="quotaFilter /*授權使用*/"
          @set-edit="setEditing"
          @set-quota-type="setQuotaType"
        ></component>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters
} from 'vuex'

import { mergeNameId } from '@/utils/'
import {
  // isUser,
  isDevice
} from '@/utils/global.js'
import { getDeviceModelIcon } from '@/config/account.js'
import {
  // FuzzyStrComp,
  getAncestorList,
  getNodeKids,
  A2ZSort,
} from '@/utils/lib.js'
import {
  getGroupLen,
  nodeGroups,
  nodeUsers,
  nodeDevices
} from '@/utils/acc_group.js'
import TopNav from '@/components/tools/TopNav.vue'
import SearchText from '@/components/Base/SearchText.vue'
import Select from '@/components/AccountManagement/tools/Select.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import GroupMain from '@/components/AccountManagement/group/GroupMain.vue'
import GroupQuota from '@/components/AccountManagement/group/GroupQuota.vue'

const euQuotafilter = {
  left: 1, // 授權未滿
  full: 2, // 授權已滿
  empty: 0, // 無授權
}

export default {
  name: 'Group',
  components: {
    TopNav,
    SearchText,
    Select,
    SmallBtn,
    GroupMain,
    GroupQuota
  },
  props: {
    staff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editing: false, // 監聽各個編輯中狀態
      tabView: null,
      quotaFilter: [],
      quotaType: null,

      timer: null,
      timerInterval: 10 * 1000,
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2']),
    ...mapState('userinfo', [
      'deviceModels',
      'groupInfoList',
      'groupTreeList',
      'accInfoList',
      'onlineList',
      'srvLiveList'
    ]),
    ...mapState('userinfo/group', ['filterText']),
    // showEdit() {
    //   return this.permissionV2.group >= PermissionLevel.L2
    // }
    navList() {
      const list = []
      list.push({
        navTitle: 'am_group_tab_group',
        comp: 'GroupMain',
        helper: null
      })
      list.push({
        navTitle: 'am_group_tab_quota',
        comp: 'GroupQuota',
        helper: null
      })
      return list
    },
    grpFilterText: {
      get() {
        return this.filterText
      },
      set(value) {
        this.updateFilterText(value)
      }
    },
    emptyFilter() {
      if (this.isQuota) {
        return !this.filterText && this.quotaFilter.length <= 0 ? true : false
      } else {
        return !this.filterText ? true : false
      }
    },
    quotaFilterOptions() {
      let list = []

      // const keys = Object.keys(euQuotafilter)
      list = Object.keys(euQuotafilter).map((key) => {
        const value = euQuotafilter[key]
        return {
          label: this.$t(`an_group_quota_filter_${value}`),
          value,
        }
      })

      return list
    },
    filteredGroupInfoList() {
      // 過濾帳號: id, info.name, video.title
      const accGroupIdList = this.filteredAccInfoList.map(
        (aInfo) => aInfo.groupId
      )

      return this.groupInfoList.filter((grp) => {
        // 過濾群組名
        const grpRet = this.filterGroupText(grp.name)
          // grp.name
          // .toLowerCase()
          // .includes(this.filterText.toLowerCase())

        // 過濾帳號
        const accRet = accGroupIdList ? accGroupIdList.includes(grp.id) : true

        if (!this.isQuota || this.quotaFilter.length <= 0) {
          return grpRet || accRet
        }

        // 過濾授權狀況
        const quotaGroupList = this.filteredQuotaGroupList.map((_g) => _g.id)
        let quotaRet = quotaGroupList ? quotaGroupList.includes(grp.id) : true

        // console.log(`[filteredGroupInfoList] grpRet, accRet, quotaRet:`, grpRet, accRet, quotaRet)
        return (grpRet || accRet) && quotaRet
      })
    },
    filterGroup() {
      // 過濾 filterText, 只過濾群組
      let data = JSON.parse(JSON.stringify(this.groupTreeList))
      if (this.emptyFilter) return data

      const matchGroupList = this.filteredGroupInfoList

      const matchAncestorList = matchGroupList.map((grp) =>
        getAncestorList(data, grp.id)
      )
      const fullAncestorList = []
      matchAncestorList.forEach((list) =>
        list.forEach((grp) => {
          if (!fullAncestorList.map((_g) => _g.id).includes(grp.id)) {
            fullAncestorList.push(grp)
          }
        })
      )

      const newData = data.filter((node) =>
        this.filterNode(
          node,
          fullAncestorList.map((grp) => grp.id)
        )
      )
      // console.log(`[filterGroup] newData:`, newData)
      return newData
    },
    groupTreeData() {
      if (!this.groupTreeList || !this.groupInfoList) return []

      const data = this.filterGroup
      data.forEach((node) => this.mergeGroupTree(node))
      // console.log(`[Group.treeData] data:`, JSON.stringify(data, ' ', 2))
      return data
    },
    filteredAccInfoList() {
      if (this.filterText) {
        const filterTextGroupList = this.groupInfoList.filter((grp) => {
          return this.filterGroupText(grp.name)
        })
        const deep = Math.max(...filterTextGroupList.map((grp) => grp.layer))
        const deepGroupList = filterTextGroupList.filter((grp) => {
          return grp.layer === deep
        })
        const key = this.filterText.toLowerCase()

        return this.accInfoList.filter((aInfo) => {
          // 過濾帳號: id, info.name, video.title
          const textRet = aInfo.id.toLowerCase().includes(key) ||
            aInfo.info.name.toLowerCase().includes(key) ||
            aInfo.video.title.toLowerCase().includes(key)
            ? true
            : false
          // 過濾出最後一層群組的帳號
          const kidRet = deepGroupList.map((grp) => grp.id).includes(aInfo.groupId)
          return textRet || kidRet
        })
      }
      return this.accInfoList
    },
    filteredQuotaGroupList() {
      const grpList = this.groupInfoList.filter((grp) => {
        const quota = this.groupQuotaSt(grp)
        const quotaSt = quota[this.quotaType]

        return this.quotaFilter.includes(quotaSt)
      })
      // console.log(`[filteredQuotaGroupList] grpList:`, grpList.map((_g) => _g.name))

      // 未滿/已滿 => 要包含其所有子群
      const tmpkidsGrpList = []
      grpList.forEach((grp) => {
        tmpkidsGrpList.push(...getNodeKids(this.groupTreeList, grp.id))
      })
      const tmpkidsGrpIds = tmpkidsGrpList.map((_g) => _g.id)
      const kidsGrpList = this.groupInfoList.filter((grp) => {
        return tmpkidsGrpIds.includes(grp.id)
      })
      // console.log(`[filteredQuotaGroupList] kidsGrpList:`, kidsGrpList)

      return [...grpList, ...kidsGrpList]
    },
    treeData() {
      if (!this.groupTreeData) return []

      const data = JSON.parse(JSON.stringify(this.groupTreeData))

      // 整合 群組＆帳號
      data.forEach((node) => this.integrateGroupTree(node))

      // 計算 群組 的子元件
      data.forEach((node) => this.integrateGroupTreeKid(node))

      // TODO: 擴充 帳號/名稱/標題 的過濾
      // data.forEach((node) => this.filterAccount(node))

      return data
    },
    rawTreeData() {
      if (!this.groupTreeList) return []

      const data = JSON.parse(JSON.stringify(this.groupTreeList))

      // 整合群組
      data.forEach((node) => this.mergeGroupTree(node))

      // 整合 群組＆帳號
      data.forEach((node) => this.integrateGroupTree(node, false))

      // 計算 群組 的子元件
      data.forEach((node) => this.integrateGroupTreeKid(node))

      // console.log(`[Group2.rawTreeData] data:`, data)
      return data
    },
    groupLen() {
      return getGroupLen(this.filterGroup)
    },
    layerBase() {
      if (this.groupInfoList && this.groupTreeList) {
        const layers = this.groupInfoList.map((gInfo) => gInfo.layer)
        const base = Math.min(...layers)
        return base
      }
      return 0
    },
    fullLayer() {
      // 全開
      const layers = this.groupInfoList.map((grp) => grp.layer)
      return Math.max(...layers) - Math.min(...layers)
    },
    openLayer() {
      // if (this.filterText) {
      //   // 全開
      //   return this.maxLayer
      // }
      return this.staff ? 1 : 0 // 0: 只開一層; 1: 開兩層
    },
    isQuota() {
      return this.tabView === 'GroupQuota'
    }
  },
  watch: {
    // quotaType() {
    //   console.log(`[quotaType.W]`, this.quotaType)
    // },
    // quotaFilter() {
    //   console.log(`[quotaFilter.W]`, this.quotaFilter)
    // }
  },
  methods: {
    ...mapActions('userinfo', ['fetchAccountManageList', 'getOnlineList', 'getSrvLiveList']),
    ...mapMutations('userinfo/group', ['updateFilterText']),
    sortAccount(accounts) {
      // “預設” 在使用者時, 跟使用者一起排序
      // “預設” 在設備時, 跟設備一起排序
      // 設備/使用者一起出現時, 先排 使用者, 再排 設備跟預設 (把預設歸在設備區排序)

      const users = []
      const devices = []

      for (const acc of accounts) {
        const bDev = isDevice(acc.kind)
        if (bDev) devices.push(acc)
        else users.push(acc)
      }

      // 各自排序
      users.sort((a, b) => A2ZSort(a.info.name, b.info.name))
      devices.sort((a, b) => A2ZSort(a.video.title, b.video.title))

      return [...users, ...devices]
    },
    nodeTypeImg(node) {
      let img = null
      let label = null
      if (node.uiIsGroup) {
        img = require('@/assets/icons/group-icon.svg')
        return { img, label }
      } else {
        let st = ''
        if (!node.enabled) {
          st = '_disable'
        } else if (node.locked) {
          st = '_locked'
        }
        // if (this.onlyUser && isUser(node.kind)) {
        //   return require('@/assets/icons/user.svg')
        // }
        // 設備
        if (isDevice(node.kind)) {
          img = require(`@/assets/icons/device_model_${getDeviceModelIcon(
            node.deviceModelId
          )}${st}.svg`)

          const supportedDeviceModel = this.deviceModels.find(
            (model) => model.id === node.deviceModelId
          )
          if (supportedDeviceModel) {
            label = supportedDeviceModel.name
          }
          return { img, label }
        }

        // 使用者
        img = require(`@/assets/icons/user${st}.svg`)

        return { img, label }
      }
    },
    isLive(acc) {
      return this.srvLiveList.find((_live) => _live.user.id === acc.id) ? true : false
    },
    isOnline(acc) {
      // 該設備 上線中
      return this.onlineList.find((_live) => _live.id === acc.id) ? true : false
    },
    isWatch(acc) {
      // 該使用者 正在看設備
      const list = []
      this.srvLiveList.forEach((_live) => {
        _live.viewer.forEach((_v) => {
          if (_v.user.id === acc.id) {
            list.push(_live)
          }
        })
      })
      return list.length > 0 ? true : false
    },
    filterNode(node, gIdList) {
      if (node.children) {
        const matchList = node.children.filter((childNode) =>
          this.filterNode(childNode, gIdList)
        )
        node.children = matchList
      }
      return gIdList.includes(node.id)
    },
    filterGroupText(grpName) {
      return grpName
          .toLowerCase()
          .includes(this.filterText.toLowerCase())
    },
    quotaSt(kQuota, gQuota, pQuota /*, grp*/) {
      // console.log(`[Group2.quotaSt] ${grp.name} kQuota, gQuota, pQuota:`, kQuota, gQuota, pQuota)
      if (pQuota < 0) return euQuotafilter.left

      if (gQuota <= 0) return euQuotafilter.empty

      // 子 >= 自己 => 滿
      if (kQuota >= gQuota) return euQuotafilter.full

      return euQuotafilter.left
    },
    groupQuotaSt(group) {
      const pGroup = this.groupInfoList.find(
        (gInfo) => gInfo.id === group.parent
      )
      const kidGroups = this.groupInfoList.filter(
        (gInfo) => gInfo.parent === group.id
      )

      let kidInQuota = 0
      let kidOutQuota = 0
      kidGroups.forEach((_k) => {
        kidInQuota += Number(_k.maxIn)
        kidOutQuota += Number(_k.maxOut)
      })

      return {
        in: this.quotaSt(kidInQuota, group.maxIn, pGroup ? pGroup.maxIn : -1, group),
        out: this.quotaSt(kidOutQuota, group.maxOut, pGroup ? pGroup.maxOut : -1, group),
      }
    },
    mergeGroupTree(node) {
      // 在这里扩展节点信息
      const grpInfo = this.groupInfoList.find((gInfo) => gInfo.id === node.id)
      const grpImg = require('@/assets/icons/group-icon.svg')

      // console.log(`[Group2.mergeGroupTree] grpInfo:`, grpInfo)
      // Copy data
      if (grpInfo) {
        const {
          auth,
          createdTime,
          id,
          layer,
          maxIn,
          maxOut,
          name,
          parent,
          updatedTime
        } = grpInfo

        node.auth = auth
        node.createdTime = createdTime
        node.id = id
        node.layer = layer
        node.maxIn = maxIn
        node.maxOut = maxOut
        node.name = name
        node.parent = parent
        node.updatedTime = updatedTime

        // UI used
        node.uiIsGroup = true
        node.uiImg = grpImg
        node.uiIsStaff = parent === '0' /*&& id === '0'*/ ? true : false
        node.uiKey = `g#${parent}_${id}`
        node.uiLabel = name
        node.uiLayer = layer
        node.uiOpened = layer - this.layerBase <= this.openLayer ? true : false
        node.uiIsLoginUser = this.getUserInfo
          ? this.getUserInfo.groupId === id
          : false
        // node.uiQuotaSt = this.groupQuotaSt(grpInfo)
      }

      // 递归处理子节点
      if (node.children) {
        node.children
          .sort((a, b) => A2ZSort(a.name, b.name)) // sort by group name
          .forEach((childNode) => this.mergeGroupTree(childNode))
      }
    },
    integrateGroupTree(node, bFilter = true) {
      let rawKids = []

      // 在这里扩展节点信息
      if (!bFilter) {
        rawKids = JSON.parse(JSON.stringify(this.accInfoList))
      } else {
        // * 取 群組下所有 帳號(不分 使用者或設備)
        rawKids = this.filteredAccInfoList.filter(
          (aInfo) => aInfo.groupId === node.id
        )
      }

      // * 順序：使用者 > 設備 > default
      let fullKids = this.sortAccount(rawKids)

      const kids = fullKids.map((aInfo) => {
        const uiIsDevice = isDevice(aInfo.kind)
        const { img, label: imgLabel } = this.nodeTypeImg(aInfo)

        return {
          ...aInfo,
          // UI used
          ...{
            uiIsGroup: false,
            uiImg: img,
            uiIsStaff:
              aInfo.groupId === '1' && aInfo.index === 1 ? true : false,
            uiIsDevice,
            uiKey: `a#${aInfo.index}_${aInfo.id}`,
            uiLabel: mergeNameId(
              uiIsDevice ? aInfo.video.title : aInfo.info.name,
              aInfo.id
            ), // `${uiIsDevice ? aInfo.video.title : aInfo.info.name}(${aInfo.id})`,
            uiLayer: node.layer + 1,
            uiOnline: uiIsDevice ? this.isOnline(aInfo) : this.isWatch(aInfo),
            uiLive: uiIsDevice ? this.isLive(aInfo) : false,
            uiImgLabel: imgLabel,
            uiIsLoginUser: this.getUserInfo
              ? this.getUserInfo.id === aInfo.id
              : false
          }
        }
      })

      // 递归处理子节点
      if (node.children) {
        node.children.forEach((childNode) => this.integrateGroupTree(childNode, bFilter))
      }

      // 陣列串接:帳號在群組前
      const orgChildren = JSON.parse(JSON.stringify(node.children))
      node.children = [...kids, ...orgChildren]
    },
    integrateGroupTreeKid(node) {
      // 在这里扩展节点信息
      const grpInfo = this.groupInfoList.find((gInfo) => gInfo.id === node.id)

      // Copy data
      if (grpInfo) {
        const group = nodeGroups(node)
        const user = nodeUsers(node, false)
        const device = nodeDevices(node)
        // UI used
        node.uiKid = { group, user, device }
      }

      // 递归处理子节点
      if (node.children) {
        node.children.forEach((childNode) =>
          this.integrateGroupTreeKid(childNode)
        )
      }
    },
    setQuotaType(type) {
      this.quotaType = type
    },
    // ---
    init() {
      this.tabView = this.navList[0].comp
    },
    async initList() {
      await this.fetchAccountManageList()

      // * 取得 connects & lives 要在授權顯示 線上帳號
      this.getOnlineList()
      // * 取得 server/list 要在授權顯示 觀看中使用者
      this.getSrvLiveList()
    },
    createTimer() {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.initList()
          this.cleanTimer()
          this.createTimer()
        }, this.timerInterval)
      }
    },
    cleanTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    onSelectTab(comp) {
      if (this.tabView !== comp) {
        this.tabView = comp
        this.onEraser()
      }

      this.setQuotaType(this.isQuota ? 'in' : null)
    },
    onEraser() {
      this.updateFilterText('')
      this.quotaFilter = []
    },
    setEditing() {
      this.editing = !this.editing
    }
  },
  // created() {},
  async mounted() {
    // get data
    await this.initList()

    // init UI
    this.init()
    // this.createTimer() // TODO:暫時先不開
    console.log(`[Group2] editing:`, this.editing)
  },
  beforeDestroy() {
    // console.log(`[Group2.beforeDestroy]`)
    this.cleanTimer()
    this.onEraser()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  // user-select: none;
}
.acc-group-wrap {
  width: 100%;
  height: 100%;
  color: $color_FFF;

  .search-wrap {
    display: flex;
    align-items: end;
    padding: px2rem(12);

    .search-input {
      width: px2rem(200);
    }
    .quato-mode:deep {
      margin-left: px2rem(12);
      width: px2rem(120);
      max-width: calc(px2rem(120) * 1.7);
      height: px2rem(36);
      &:lang(en) {
        min-width: calc(px2rem(120) * 1.7);
      }
      .select {
        height: 100%;
        .select-selected {
          padding-left: 1rem;
          padding-right: 0.5rem;
          img {
            margin-left: 0.5rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
    .eraser,
    .count {
      margin-left: px2rem(12);
    }
  }

  .divider {
    @include horzontal_divider;
  }
  .content {
    height: calc(100% - px2rem(100)); // 60(nav) + 40(sub-nav)
  }
}
</style>
