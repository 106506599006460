<template>
  <div class="group-main-wrap">
    <div class="tree-view">
      <GroupTreeNode
        v-for="(node, idx) in uiTreeData"
        :key="`${node.uiKey}_${idx}`"
        :nodeData="node"
        :layerBase="layerBase"
        :openLayer="uiOpenLayer"
        @select="onSelectNode"
        :multiple="null"
        :value="currNode ? currNode.id : null"
        :disabledNode="null /*TODO*/"
        @checked="(node) => null"
        :userCount="true"
        :deviceCount="true"
        :controlQuota="false"
        @plus="(node) => null"
        @minus="(node) => null"
      />
    </div>
    <div class="divider"></div>
    <div class="detail-view">
      <GroupMainEdit
        v-if="currNode"
        :staff="staff"
        :nodeData="currNode"
        :layerBase="layerBase"
        @update_edit="onUpdateEditSt"
        @set-edit="$emit('set-edit')"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'

import GroupTreeNode from '@/components/AccountManagement/tools/GroupTreeNode.vue'
import GroupMainEdit from '@/components/AccountManagement/group/Edit/GroupMainEdit.vue'

export default {
  name: 'GroupMain',
  components: {
    GroupTreeNode,
    GroupMainEdit,
  },
  props: {
    staff: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default() {
        return []
      }
    },
    layerBase: {
      type: Number,
      // default: 0
    },
    openLayer: {
      type: Number,
      // default: 2
    },
    fullLayer: {
      type: Number,
      // default: 0
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currNode: null,
      bSave: false,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState('userinfo', ['groupTreeList', 'groupInfoList']),
    ...mapState('userinfo/group', ['filterText']),
    uiOpenLayer() {
      if (this.filterText) {
        return this.fullLayer
      }
      return this.openLayer
    },
    uiTreeData() {
      const tmpTreeData = JSON.parse(JSON.stringify(this.treeData))

      return tmpTreeData
    }
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    // ---
    onSelectNode(node) {
      // console.log(`[onSelectNode] node:`, node.id, this.bSave)
      if (this.bSave) return
      this.currNode = { ...node }
    },
    // onExpandNode(node) {
    //   console.log(`[onExpandNode] node:`, node.id, node.uiOpened)
    //   node.uiOpened = !node.uiOpened
    // },
    onUpdateEditSt(status) {
      this.bSave = status
    },
  },
  // created() {},
  mounted() {
    // console.log(`[GroupMain] treeData: `, JSON.stringify(this.treeData, ' ', 2))
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$TreeW: calc(764 / 1230 * 100%);
$DetailW: calc(100% - $TreeW);

* {
  box-sizing: border-box;
  // user-select: none;
}
.group-main-wrap {
  display: flex;
  height: 100%;
  .tree-view {
    margin-bottom: 0.25rem;
    width: $TreeW;
    overflow-y: scroll;
    overflow-x: auto;
  }
  .divider {
    @include vertical_divider;
  }
  .detail-view {
    margin-bottom: 0.25rem;
    // padding-left: px2rem(12);
    width: $DetailW;
  }
}
</style>
