import { render, staticRenderFns } from "./GroupMain.vue?vue&type=template&id=39d43f31&scoped=true&"
import script from "./GroupMain.vue?vue&type=script&lang=js&"
export * from "./GroupMain.vue?vue&type=script&lang=js&"
import style0 from "./GroupMain.vue?vue&type=style&index=0&id=39d43f31&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d43f31",
  null
  
)

export default component.exports